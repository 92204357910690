import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import i18n from "i18next";
import { FlexboxGrid, Panel } from "rsuite";
import Steps from "../../../components/molecules/Steps";

interface RegisterProps {}

const Register = ({}: RegisterProps) => {
  return (
    <>
      <Steps activeStep={1} />
      <FlexboxGrid justify="center">
        <FlexboxGrid.Item
          colspan={24}
          style={{
            padding: "30px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Panel shaded>
            <div
              style={{
                maxWidth: "500px",
              }}
            >
              <FlexboxGrid
                justify="center"
                style={{ color: "#6BA0FC", paddingTop: "53px" }}
              >
                <FontAwesomeIcon icon={faEnvelope} size="6x" />
              </FlexboxGrid>
              <FlexboxGrid justify="center">
                <p className="thank-you-title">
                  {i18n.t("register_form.emailed") as string}
                </p>
              </FlexboxGrid>
              <FlexboxGrid justify="center">
                <p className="thank-you-message">
                  {i18n.t("register_form.completed") as string}
                  <br />
                  {i18n.t("register_form.completion_detail") as string}
                </p>
              </FlexboxGrid>
            </div>
          </Panel>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </>
  );
};

export default Register;
