import i18n from "i18next";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Col, Divider, FlexboxGrid, Header } from "rsuite";
import Plan from "../../../components/molecules/Plan";
import Steps from "../../../components/molecules/Steps";

import MiiTelLogo from "../../../assets/img/logo_miitel.svg";
import MiiTelForZoomLogo from "../../../assets/img/MiiTel_Zoom_logo.svg";

interface Props {}

const SelectService: React.FC<Props> = () => {
  const navigate = useNavigate();

  const handleServiceSelect = (service: string) => {
    if (service === "miitel-for-zoom") {
      navigate(`/select-functions?service=${service}`);
    } else {
      return;
    }
  };

  return (
    <>
      <Steps activeStep={2} />
      <FlexboxGrid justify="center">
        <FlexboxGrid.Item
          style={{
            paddingTop: "25px",
            display: "flex",
            justifyContent: "center",
          }}
          colspan={24}
        >
          <Header
            as="h3"
            style={{
              fontSize: "24px",
              color: "#083464",
            }}
          >
            {i18n.t("services.title") as string}
          </Header>
          <Divider
            style={{
              marginTop: "2px",
              marginBottom: "12px",
            }}
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item
          colspan={24}
          style={{
            padding: "30px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <FlexboxGrid justify="center">
            <FlexboxGrid.Item
              as={Col}
              lg={12}
              md={12}
              sm={24}
              colspan={24}
              style={{
                padding: "30px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Plan
                subscription_id="bbb"
                handleSelect={() => handleServiceSelect("miitel-for-zoom")}
                hidden={false}
                contentImg={MiiTelForZoomLogo}
                header={i18n.t("services.miitel_for_zoom") as string}
                description={""}
                width={250}
              />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item
              as={Col}
              lg={12}
              md={12}
              sm={24}
              colspan={24}
              style={{
                padding: "30px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Plan
                subscription_id="aaa"
                handleSelect={() => handleServiceSelect("miitel")}
                hidden={false}
                contentImg={MiiTelLogo}
                header={i18n.t("services.miitel") as string}
                description={""}
                width={160}
                canUse={false}
              />
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </>
  );
};

export default SelectService;
