import { CountryCode } from "config/BaseConfig";
import { InputNumber as RsuiteInputNumber } from "rsuite";
import classes from "./InputNumber.module.scss";

interface Props {
  name: string;
  value: number;
  disabled: boolean;
  onChange: (
    value: string | number | readonly string[],
    event: React.SyntheticEvent<Element, Event>
  ) => void;
  min?: number;
}

const InputNumber = ({ name, value, disabled, onChange, min = 0 }: Props) => {
  return (
    <RsuiteInputNumber
      className={
        CountryCode === "US"
          ? classes.main_css_us + " custom-input-number"
          : classes.main_css_jp + " custom-input-number"
      }
      name={name}
      value={value}
      disabled={disabled}
      onChange={onChange}
      min={min}
    />
  );
};

export default InputNumber;
