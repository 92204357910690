import { Amplify, Auth } from "aws-amplify";
import { Button, ButtonToolbar, Content, Form, Panel } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { AuthData } from "../../../config/BaseConfig";

Amplify.configure({
  Auth: AuthData,
});

export default function Login() {
  return (
    <div>
      <Panel header={<h3>Online Application Console Login</h3>} bordered>
        <Content>
          <div>
            <Form>
              <Form.Group>
                <ButtonToolbar>
                  <Button
                    appearance="ghost"
                    onClick={() =>
                      Auth.federatedSignIn({ customProvider: "Google" })
                    }
                  >
                    Login
                  </Button>
                </ButtonToolbar>
              </Form.Group>
            </Form>
          </div>
        </Content>
      </Panel>
    </div>
  );
}
