import i18n from "i18next";
import React from "react";
import { FlexboxGrid } from "rsuite";
import Step from "../../atoms/Step";

interface Props {
  activeStep: number;
}

const Steps: React.FC<Props> = ({ activeStep }) => {
  return (
    <FlexboxGrid justify="center">
      <FlexboxGrid.Item
        style={{
          paddingBlock: "23px",
          background: "#FFFFFF",
          boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.12)",
          overflow: "hidden",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
        colspan={24}
      >
        <div
          className="content"
          style={{
            width: "100%",
          }}
        >
          <ul
            className="steps"
            style={{
              paddingInlineStart: "0px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Step
              title="1"
              text={i18n.t("steps.step_1") as string}
              isActive={activeStep === 1 ? true : false}
              isDone={activeStep > 1 ? true : false}
            />
            <Step
              title="2"
              text={i18n.t("steps.step_2") as string}
              isActive={activeStep === 2 ? true : false}
              isDone={activeStep > 2 ? true : false}
            />
            <Step
              title="3"
              text={i18n.t("steps.step_3") as string}
              isActive={activeStep === 3 ? true : false}
              isDone={activeStep > 3 ? true : false}
            />
            <Step
              title="4"
              text={i18n.t("steps.step_4") as string}
              isActive={activeStep === 4 ? true : false}
              isDone={activeStep > 4 ? true : false}
            />
            <Step
              title="5"
              text={i18n.t("steps.step_5") as string}
              isActive={activeStep === 5 ? true : false}
              isDone={activeStep > 5 ? true : false}
            />
          </ul>
        </div>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
};
export default Steps;
