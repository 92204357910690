import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import i18n from "i18next";
import ReCAPTCHA from "react-google-recaptcha";
import Checkbox, { ValueType } from "rsuite/esm/Checkbox";
import { CheckboxGroup, FlexboxGrid, Form, Message } from "rsuite";

import classes from "./ContinuousPage.module.scss";
import usePageTracking from "hooks/useTracking";
import { DeployPage } from "config/BaseConfig";
import ConsoleAPIClient from "service/utils/ConsoleAPIClient";
import { ContinueOrCancelRequest, FormValueType } from "./type";
import InputCheckbox from "../../../components/atoms/InputCheckbox";
import InputNumberGroup from "../../../components/molecules/InputNumberGroup";
import Button from "components/atoms/Button";
import FormLabel from "components/atoms/FormLabel";
import Input from "components/atoms/Input";
import InputPicker from "components/atoms/InputPicker";
import FormHeaderGroup from "components/molecules/FormHeaderGroup";
import { isValidEmail, isValidTenantCode } from "service/utils/ValidationUtil";
import {
  requestTypes,
  initialFormValues,
  cancelReasons,
  contractTypes,
} from "config/ContinuousFormConfig";
import { AxiosResponse } from "axios";
import { ValuesEvent, useFormValuesChange } from "hooks/useFormValuesChange";
import { useErrorMessages } from "hooks/useErrorMessage";

type ContinuePageProps = {};

const ContinuePage: React.FC<ContinuePageProps> = () => {
  // hooks
  usePageTracking();
  const search = useLocation().search;
  const navigate = useNavigate();
  const {
    values,
    setValuesEvent,
    handleValueInputChange,
    handleCheckInputChange,
  } = useFormValuesChange<FormValueType>(initialFormValues);
  const errorRef = useRef<HTMLDivElement>(null);

  // states
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [progressMessage, setProgressMessage] = useState<string>("");
  const { errors, showErrorMessage, clearErrors } = useErrorMessages();

  // useEffect
  useEffect(() => {
    document.title =
      "トライアルプラン解約・継続申し込み | MiiTel Meetings（ミーテルミーティング）";
  }, []);

  useEffect(() => {
    (async () => {
      await ConsoleAPIClient.getWithoutAuth("/api/health");
    })();
  }, [navigate, search]);

  useEffect(() => {
    if (values.request_type === "continue") {
      setValuesEvent(1, "how_many_users");
      setValuesEvent([], "cancel_reasons");
      setValuesEvent("yearly", "contract_type");
    } else if (values.request_type === "cancel") {
      setValuesEvent(0, "how_many_users");
      setValuesEvent("", "contract_type");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.request_type]);

  useEffect(() => {
    if (errors.length > 0 && errorRef.current) {
      errorRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [errors]);

  // functions
  const isBlankRequiredFields = (values: FormValueType): boolean => {
    return (
      !values.company_name ||
      !values.customer_name ||
      !values.tenant_code ||
      !values.phone_number ||
      !values.email ||
      !values.request_type ||
      (values.request_type === "cancel" &&
        values.cancel_reasons.length === 0) ||
      (values.cancel_reasons.includes("その他") &&
        values.additional_message === "")
    );
  };

  // handlers
  const handleSubmit = useCallback(async () => {
    setIsSubmitting(true);
    clearErrors();
    const errorMessages: string[] = [];
    if (isBlankRequiredFields(values)) {
      errorMessages.push(
        i18n.t("continuous_form.error.messages.required") as string
      );
    }
    if (!isValidEmail(values.email)) {
      errorMessages.push(
        i18n.t("continuous_form.error.messages.email") as string
      );
    }
    if (!isValidTenantCode(values.tenant_code)) {
      errorMessages.push(
        i18n.t("continuous_form.error.messages.tenant_code") as string
      );
    }
    if (values.accepted === "0") {
      errorMessages.push(
        i18n.t("continuous_form.error.messages.accepted") as string
      );
    }
    if (errorMessages.length > 0) {
      showErrorMessage(errorMessages);
      setIsSubmitting(false);
      return;
    }

    const requestBody: ContinueOrCancelRequest = {
      continue_or_cancel_request: {
        email: values.email,
        company_name: values.company_name,
        customer_name: values.customer_name,
        tenant_code: values.tenant_code,
        phone_number: values.phone_number,
        request_source: "mm-free-trial-202305",
        request_type: values.request_type,
        contract_type: values.contract_type,
        request_data: {
          how_many_users: values.how_many_users,
          cancel_reasons: values.cancel_reasons,
          additional_message: values.additional_message,
        },
      },
    };

    try {
      setProgressMessage(
        i18n.t("continuous_form.progress_messages.registering") as string
      );
      await ConsoleAPIClient.postWithoutAuth(
        "/api/jp/v1/continue-or-cancel-request",
        requestBody
      );
      navigate("/finished?pageType=continuous");
      setIsSubmitting(false);
    } catch (error: AxiosResponse | any) {
      console.error("Error in MiitelMeetingsRegister when submit", error);
      errorMessages.push(
        error?.response?.data?.detail
          ? error.response.data.detail
          : (i18n.t("continuous_form.error.messages.default") as string)
      );
      showErrorMessage(errorMessages);
      setProgressMessage("");
      setIsSubmitting(false);
    }
  }, [navigate, values, showErrorMessage, clearErrors]);

  const handleMinus = (target: string) => {
    if (target !== "how_many_users") return;
    const nextValue = Number(values[target]) - 1;
    if (nextValue < 1) {
      return;
    }
    setValuesEvent(nextValue, target);
  };

  const handlePlus = (target: string) => {
    if (target !== "how_many_users") return;
    const nextValue = Number(values[target]) + 1;
    setValuesEvent(nextValue, target);
  };

  const handleRequestTypesSelectInputChange = (
    value: ValuesEvent,
    _: React.SyntheticEvent<Element, Event>
  ) => {
    setValuesEvent(value, "request_type");
  };

  const handleContractTypesSelectInputChange = (
    value: ValuesEvent,
    _: React.SyntheticEvent<Element, Event>
  ) => {
    setValuesEvent(value, "contract_type");
  };

  // CheckboxGroup
  const handleCheckBoxChange = (value: ValueType[]) => {
    setValuesEvent(value, "cancel_reasons");
  };

  return (
    <div>
      <Helmet>
        <title>
          継続ご利用確認フォーム | MiiTel Meetings（ミーテルミーティング）
        </title>
      </Helmet>
      <FlexboxGrid justify="center" style={{ paddingBlock: "60px" }}>
        <FlexboxGrid
          justify="center"
          style={{
            width: "100%",
          }}
        >
          <FormHeaderGroup sub_header={""} header={"継続ご利用確認フォーム"} />
          <FlexboxGrid.Item
            style={{
              paddingTop: "25px",
              display: "flex",
              justifyContent: "center",
            }}
            colspan={24}
          >
            <Form className={classes["form-content-flexgrid"]}>
              {errors.length > 0 ? (
                <Message
                  className={classes["message-alert"]}
                  showIcon
                  type="error"
                  header="Error"
                  ref={errorRef}
                >
                  {errors.map((error, idx) => {
                    return <p key={idx}>{error}</p>;
                  })}
                </Message>
              ) : null}
              <Form.Group
                controlId="request_type"
                style={{ paddingBlock: "20px", marginBottom: "0px" }}
              >
                <FormLabel
                  text="トライアル期間終了後のご契約に関して"
                  required={true}
                />
                <InputPicker
                  name="request_type"
                  data={requestTypes}
                  value={values.request_type}
                  onChange={handleRequestTypesSelectInputChange}
                  disabled={false}
                />
              </Form.Group>

              <Form.Group controlId="tenant_code">
                <FormLabel
                  text={i18n.t("functions.tenant_code") as string}
                  required={true}
                />
                <Input
                  name="tenant_code"
                  value={values.tenant_code}
                  onChange={handleValueInputChange}
                  type="tenant_code"
                  disabled={false}
                  step=""
                  placeHolder={
                    i18n.t("functions.samples.tenant_code") as string
                  }
                />
                <Form.HelpText>
                  *テナント名がわからない場合は、MiiTel Analytics
                  の右上に表示されるアカウントアイコンからご確認いただけます。
                </Form.HelpText>
              </Form.Group>

              {values.request_type === "cancel" ? null : (
                <Form.Group
                  controlId="how_many_users"
                  style={{ paddingBlock: "20px", marginBottom: "0px" }}
                >
                  <FormLabel
                    text={i18n.t("functions.zoom_user_amount") as string}
                    required={true}
                  />
                  <InputNumberGroup
                    name="how_many_users"
                    value={values.how_many_users}
                    onChange={handleValueInputChange}
                    handleMinus={handleMinus}
                    handlePlus={handlePlus}
                    disabled={false}
                  />
                </Form.Group>
              )}

              {values.request_type === "cancel" ? null : (
                <Form.Group
                  controlId="contract_type"
                  style={{ paddingBlock: "20px", marginBottom: "0px" }}
                >
                  <FormLabel text="契約期間" required={true} />
                  <InputPicker
                    name="contract_type"
                    data={contractTypes}
                    value={values.contract_type}
                    onChange={handleContractTypesSelectInputChange}
                    disabled={false}
                  />
                  <Form.HelpText>
                    *年次契約の場合は初期費用が無料です。月次契約で10ID以下のご契約の場合は、初月に事務手数料89,000円が発生します。
                  </Form.HelpText>
                </Form.Group>
              )}

              <Form.Group
                controlId="company_name"
                style={{ paddingBlock: "20px", marginBottom: "0px" }}
              >
                <FormLabel
                  text={i18n.t("register_form.company_name") as string}
                  required={true}
                />
                <Input
                  name="company_name"
                  value={values.company_name}
                  onChange={handleValueInputChange}
                  type="text"
                  disabled={false}
                  step=""
                  placeHolder={
                    i18n.t("register_form.samples.company_name") as string
                  }
                />
              </Form.Group>

              <Form.Group controlId="name">
                <FormLabel
                  text={i18n.t("register_form.last_name") as string}
                  required={true}
                />
                <Input
                  name="customer_name"
                  value={values.customer_name}
                  onChange={handleValueInputChange}
                  type="text"
                  disabled={false}
                  step=""
                  placeHolder={i18n.t("card_setup.samples.ceo_name") as string}
                />
              </Form.Group>

              <Form.Group
                controlId="phone_number"
                style={{ paddingBlock: "20px", marginBottom: "0px" }}
              >
                <FormLabel
                  text={i18n.t("register_form.phone_number") as string}
                  required={true}
                />
                <Input
                  name="phone_number"
                  value={values.phone_number}
                  onChange={handleValueInputChange}
                  type="phone_number"
                  disabled={false}
                  step=""
                  placeHolder={
                    i18n.t("register_form.samples.phone_number") as string
                  }
                />
              </Form.Group>

              <Form.Group
                controlId="email"
                style={{ paddingBlock: "20px", marginBottom: "0px" }}
              >
                <FormLabel
                  text={i18n.t("register_form.email") as string}
                  required={true}
                />
                <Input
                  name="email"
                  value={values.email}
                  onChange={handleValueInputChange}
                  type="email"
                  disabled={false}
                  step=""
                  placeHolder={i18n.t("register_form.samples.email") as string}
                />
              </Form.Group>

              <Form.Group
                controlId="cancel_reasons"
                style={{
                  paddingBlock: "20px",
                  marginBottom: "0px",
                  display: values.request_type === "cancel" ? "block" : "none",
                }}
              >
                <FormLabel text="ご解約理由（ご解約をされる場合）" required />
                <Form.HelpText>
                  *ご解約理由に当てはまるものをご選択ください（複数選択可）
                  <br />
                  *該当する理由がない方は「その他」を選択の上、その他ご意見に内容をご記載ください
                </Form.HelpText>
                {/* TODO: Move to atom component */}
                <CheckboxGroup
                  inline
                  name="checkboxList"
                  value={values.cancel_reasons}
                  onChange={handleCheckBoxChange}
                  style={{ backgroundColor: "#f7f7fa", marginLeft: "0px" }}
                  className="rs-flex-box-grid rs-flex-box-grid-top rs-flex-box-grid-start"
                >
                  {cancelReasons.map((item) => (
                    <FlexboxGrid.Item key={item} colspan={24}>
                      <InputCheckbox value={item} />
                    </FlexboxGrid.Item>
                  ))}
                </CheckboxGroup>
              </Form.Group>

              <Form.Group
                controlId="additional_message"
                style={{ paddingBlock: "20px", marginBottom: "0px" }}
              >
                <FormLabel text="その他ご意見" />
                <Input
                  name="additional_message"
                  value={values.additional_message}
                  onChange={handleValueInputChange}
                  type="text"
                  disabled={false}
                  step=""
                  placeHolder={
                    "MiiTel Meetings に欲しい（欲しかった）機能やサポート等"
                  }
                  rows={5}
                />
              </Form.Group>

              {isSubmitting && progressMessage !== "" ? (
                <Form.Group>
                  <Message
                    className={classes["message-alert"]}
                    showIcon
                    type="info"
                    header={
                      i18n.t(
                        "continuous_form.progress_messages.checking"
                      ) as string
                    }
                  >
                    <p>{progressMessage}</p>
                  </Message>
                </Form.Group>
              ) : null}

              <Form.Group controlId="accepted">
                <FlexboxGrid justify="center">
                  <Checkbox
                    name="accepted"
                    value={values.accepted}
                    onChange={handleCheckInputChange}
                    disabled={false}
                  >
                    <span className={classes["accept-checkbox-text"]}>
                      <a
                        href="https://www.revcomm.co.jp/privacypolicy/"
                        target="_blank"
                        rel="noreferrer noopener"
                        className={classes["accept-checkbox-text-link"]}
                      >
                        個人情報の取り扱い
                      </a>{" "}
                      と{" "}
                      <a
                        href="https://www.revcomm.co.jp/agreement/"
                        target="_blank"
                        rel="noreferrer noopener"
                        className={classes["accept-checkbox-text-link"]}
                      >
                        個人情報保護方針
                      </a>{" "}
                      に同意する
                    </span>
                  </Checkbox>
                </FlexboxGrid>
              </Form.Group>
              <Form.Group
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <ReCAPTCHA
                  sitekey="6LdfqOQZAAAAAFe8i_GhcQWu39jG2fcp85xOcKUu"
                  onChange={(e: any) => console.log(e)}
                />
              </Form.Group>

              <Form.Group
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <Button
                  text={
                    DeployPage === "APPLICATION_FORM"
                      ? "下記に同意し支払い情報入力へ"
                      : "入力内容を送信"
                  }
                  onClick={handleSubmit}
                  isLoading={isSubmitting}
                />
              </Form.Group>
            </Form>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </FlexboxGrid>
    </div>
  );
};

export default ContinuePage;
