import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import i18n from "i18next";
import { CheckboxGroup, FlexboxGrid, Form, Message } from "rsuite";
import Checkbox from "rsuite/esm/Checkbox";
import { useRecoilValue, useSetRecoilState } from "recoil";
import ReCAPTCHA from "react-google-recaptcha";
import ReactGA from "react-ga4";

import classes from "./MiitelMeetings.module.scss";
import IMG_MV from "../../../assets/img/img_MV.png";
import SecondaryInput from "components/atoms/SecondaryInput";
import SecondaryFormLabel from "components/atoms/SecondaryFormLabel";
import SecondaryInputCheckbox from "components/atoms/SecondaryInputCheckbox";
import SecondaryInputPicker from "components/atoms/SecondaryInputPicker";
import SecondaryButton from "components/atoms/SecondaryButton";
import FunctionItem from "components/atoms/FunctionItem";
import {
  ApplicationSettings,
  applicationSettingsState,
} from "stores/applicationSettings";
import { useApplicationSettings } from "hooks/useApplicationSettings";
import {
  ValueChangeHandler,
  useFormValuesChange,
} from "hooks/useFormValuesChange";
import {
  isValidEmail,
  isValidResponseTenantCode,
  isValidTenantCode,
} from "service/utils/ValidationUtil";
import ConsoleAPIClient from "service/utils/ConsoleAPIClient";
import { leadIdState } from "stores/customerSettings";
import {
  HOW_MANY_USERS,
  MEETING_TOOLS,
  NUM_OF_EMPLOYEES_SELECTIONS,
  initialFormValues,
} from "config/MiiTelMeetingsFormConfig";
import {
  CustomerCreateRequest,
  IsTenantCodeExistRequest,
  IsTenantCodeExistResponse,
  FormValueType,
  ToolsType,
  toolsMapping,
  IsDeniedEmailRequest,
  IsDeniedEmailResponse,
  IsCompetitorRequest,
  IsCompetitorResponse,
} from "./type";
import { useErrorMessages } from "hooks/useErrorMessage";

interface MiitelMeetingsRegisterProps {
  utmSource: string;
  utmMedium: string;
  utmCampaign: string;
  utmContent: string;
}

const MiitelMeetingsRegister: React.FC<MiitelMeetingsRegisterProps> = (
  props: MiitelMeetingsRegisterProps
) => {
  // configs
  const FUNCTIONS_LIST = [
    [
      {
        label: i18n.t(
          "register_form_mm.functions.generate_meetings_note"
        ) as string,
        isCheck: true,
      },
      {
        label: i18n.t("register_form_mm.functions.topics") as string,
        isCheck: true,
      },
    ],
    [
      {
        label: i18n.t("register_form_mm.functions.transcription") as string,
        isCheck: true,
      },
      {
        label: i18n.t("register_form_mm.functions.sentiment") as string,
        isCheck: true,
      },
    ],
    [
      {
        label: i18n.t(
          "register_form_mm.functions.quantitative_evaluation"
        ) as string,
        isCheck: true,
      },
      {
        label: i18n.t("register_form_mm.functions.etc") as string,
        isCheck: false,
      },
    ],
  ];

  const TENANT_CODE_DESCRIPTION = [
    i18n.t("register_form_mm.tenant_code_description1") as string,
    i18n.t("register_form_mm.tenant_code_description2") as string,
    i18n.t("register_form_mm.tenant_code_description3") as string,
    i18n.t("register_form_mm.tenant_code_description4") as string,
  ];

  // hooks
  const navigate = useNavigate();
  const applicationSettings = useRecoilValue(applicationSettingsState);
  const { handleZoomUserSelectInputChange, handleInputChange } =
    useApplicationSettings();
  const {
    values,
    setValuesEvent,
    handleValueInputChange,
    handleCheckInputChange,
  } = useFormValuesChange<FormValueType>(initialFormValues);
  const { errors, showErrorMessage, clearErrors } = useErrorMessages();
  const setLeadId = useSetRecoilState(leadIdState);
  const errorRef = useRef<HTMLDivElement>(null);

  // states
  const { utmSource, utmMedium, utmCampaign, utmContent } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [progressMessage, setProgressMessage] = useState<string>("");

  // useEffect
  useEffect(() => {
    document.title =
      "トライアルプラン申し込み | MiiTel Meetings（ミーテルミーティング）";
  }, []);

  useEffect(() => {
    if (errors.length > 0 && errorRef.current) {
      errorRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [errors]);

  // OnChangeでEventが受け取れないため、直接Nameを指定してStateを更新する
  const handleNumberOfEmployeesSelectInputChange: ValueChangeHandler = (
    value,
    _
  ) => setValuesEvent(value, "number_of_employees");

  // functions
  const isBlankRequiredFields = (
    values: FormValueType,
    applicationSettings: ApplicationSettings
  ): boolean => {
    return (
      !values.company_name ||
      !values.first_name ||
      !values.last_name ||
      !values.phone_number ||
      !values.email ||
      !values.number_of_employees ||
      !values.meeting_tools ||
      !values.accepted ||
      !values.ceo_name ||
      !values.headquarter || // 住所
      !applicationSettings.tenant_code ||
      applicationSettings.zoom_user === 0 ||
      values.meeting_tools.length === 0
    );
  };

  const handleSubmit = useCallback(async () => {
    ReactGA.initialize("G-DSDMM4BM02");
    ReactGA.event({
      category: "Form",
      action: "Submit",
      label: "Application",
    });
    setIsSubmitting(true);
    clearErrors();
    const errorMessages: string[] = [];
    if (isBlankRequiredFields(values, applicationSettings)) {
      errorMessages.push(
        i18n.t("register_form_mm.error.messages.required") as string
      );
    }
    if (
      applicationSettings.tenant_code.length < 4 ||
      applicationSettings.tenant_code.length > 33 ||
      !isValidTenantCode(applicationSettings.tenant_code)
    ) {
      errorMessages.push(
        i18n.t("register_form_mm.error.messages.tenant_code") as string
      );
    }
    if (!isValidEmail(values.email)) {
      errorMessages.push(
        i18n.t("register_form_mm.error.messages.email") as string
      );
    }
    if (values.accepted === "0") {
      errorMessages.push(
        i18n.t("register_form_mm.error.messages.accepted") as string
      );
    }

    const requestBody: IsTenantCodeExistRequest = {
      tenant: {
        tenant_code: applicationSettings.tenant_code,
      },
    };
    try {
      const emailCheckRequestBody: IsDeniedEmailRequest = {
        mail_check_request: {
          email: values.email,
        },
      };
      // Email check
      const emailCheckResult = await ConsoleAPIClient.postWithoutAuth(
        "/api/jp/v1/emails/validation",
        emailCheckRequestBody
      );
      const emailCheckResultData: IsDeniedEmailResponse = emailCheckResult.data;
      if (emailCheckResultData.mail_check_result.is_denied) {
        errorMessages.push(
          i18n.t("register_form_mm.error.messages.denied_email") as string
        );
      }

      // Competitor check
      const competitorCheckRequestBody: IsCompetitorRequest = {
        competitors_check_request: {
          company_name: values.company_name,
        },
      };
      const competitorCheckResult = await ConsoleAPIClient.postWithoutAuth(
        "/api/jp/v1/competitors",
        competitorCheckRequestBody
      );
      const { competitors_check_result }: IsCompetitorResponse =
        competitorCheckResult.data;
      // 競合企業の場合はエラーとして登録できないようにする。アライアンス企業の場合は、通常通り申込を実行する。
      if (competitors_check_result.is_competitor) {
        errorMessages.push(
          i18n.t("register_form_mm.error.messages.denied_register") as string
        );
      }

      if (errorMessages.length > 0) {
        showErrorMessage(errorMessages);
        setIsSubmitting(false);
        return;
      }

      // Check if tenant_code is already used
      let response;
      response = await ConsoleAPIClient.postWithoutAuth(
        "/api/common/v1/tenants",
        requestBody
      );
      setProgressMessage(
        i18n.t("register_form_mm.progress_messages.checking") as string
      );
      const tenant_response: IsTenantCodeExistResponse = response.data;
      if (
        tenant_response.tenant.is_exist ||
        !isValidResponseTenantCode(applicationSettings.tenant_code)
      ) {
        errorMessages.push(
          i18n.t("register_form_mm.error.messages.tenant_code_unique") as string
        );
        showErrorMessage(errorMessages);
        setProgressMessage("");
        setIsSubmitting(false);
        return;
      }
      // 登録処理
      setProgressMessage(
        i18n.t("register_form_mm.progress_messages.registering") as string
      );
      const newMeetingTools = values.meeting_tools.map(
        (item: ToolsType) => toolsMapping[item]
      );
      const customerCreateRequestBody: CustomerCreateRequest = {
        customer: {
          company_name: values.company_name,
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
          title: "", // MM申込フォーム未使用のため、空文字列を設定
          phone_number: values.phone_number,
          how_many_users: applicationSettings.zoom_user,
          number_of_employees: values.number_of_employees,
          meeting_tools: newMeetingTools.join(";"),
          utm_source: utmSource,
          utm_medium: utmMedium,
          utm_campaign: utmCampaign,
          utm_content: utmContent,
          is_document_request_cc: false as boolean, // MM申込フォーム未使用のため、falseを設定
          is_document_request_recpod: false as boolean, // MM申込フォーム未使用のため、falseを設定
          is_online_application: true as boolean,
          ceo_name: values.ceo_name,
          headquarter: values.headquarter,
          tenant_code: applicationSettings.tenant_code,
          zoom_user: applicationSettings.zoom_user,
          is_miitel_user: "", // MM申込フォーム未使用のため、空文字列を設定
        },
      };
      // バックエンドへのリクエスト
      // あえてResponseを待たないように変更。リクエストが成功した場合はリダイレクトする
      response = ConsoleAPIClient.postWithoutAuth(
        "/api/jp/v1/customers",
        customerCreateRequestBody
      );
      // 既存の処理を一旦コメントアウト※setLeadIdで戻り値を格納しているが、おそらく不要だと思われるため、少し経過観察後に削除する
      // response = await ConsoleAPIClient.postWithoutAuth(
      //   "/api/jp/v1/customers",
      //   customerCreateRequestBody
      // );
      // const { customer }: CustomerCreateResponse = response.data;
      // setLeadId(customer.id);
      // リダイレクト処理
      setProgressMessage("");
      navigate("/finished?pageType=application");
    } catch (error) {
      console.error("Error in MiitelMeetingsRegister when submit", error);
      showErrorMessage();
      setProgressMessage("");
      setIsSubmitting(false);
    }
  }, [
    clearErrors,
    values,
    applicationSettings,
    showErrorMessage,
    utmSource,
    utmMedium,
    utmCampaign,
    utmContent,
    // setLeadId,
    navigate,
  ]);

  return (
    <div className={classes["body"]}>
      <FlexboxGrid className={classes["form-container"]}>
        <Form className={classes["form-frame"]}>
          <h3 className={classes["form-frame-title"]}>
            {i18n.t("register_form_mm.form_title") as string}
          </h3>
          {errors.length > 0 ? (
            <Form.Group className={classes["form-input-group"]}>
              <Message
                className={classes["message-alert"]}
                showIcon
                type="error"
                header="Error"
                ref={errorRef}
              >
                {errors.map((error, idx) => {
                  return <p key={idx}>※ {error}</p>;
                })}
              </Message>
            </Form.Group>
          ) : null}
          <Form.Group
            controlId="tenant_code"
            className={classes["form-input-group"]}
          >
            <SecondaryFormLabel
              text={i18n.t("register_form_mm.tenant_code") as string}
              required={true}
            />
            <SecondaryInput
              name="tenant_code"
              value={applicationSettings.tenant_code}
              onChange={handleInputChange}
              type="text"
              disabled={false}
              step=""
              placeHolder={
                i18n.t("register_form_mm.samples.tenant_code") as string
              }
            />
            <FlexboxGrid>
              {TENANT_CODE_DESCRIPTION.map((item, index) => {
                return (
                  <FlexboxGrid.Item
                    className={classes["form-input-group-additional-text"]}
                    colspan={24}
                    key={item}
                  >
                    {item}
                  </FlexboxGrid.Item>
                );
              })}
            </FlexboxGrid>
          </Form.Group>
          <Form.Group
            controlId="meeting_tools"
            className={classes["form-input-group"]}
          >
            <SecondaryFormLabel
              text={i18n.t("register_form.meeting_tools") as string}
              required={true}
            />
            <FlexboxGrid className={classes["form-input-group-frame"]}>
              <CheckboxGroup
                name="checkboxList"
                value={values.meeting_tools}
                onChange={handleCheckInputChange}
                className={classes["form-input-group-checkbox"]}
              >
                {MEETING_TOOLS.map((item) => (
                  <FlexboxGrid.Item
                    key={item}
                    colspan={24}
                    className={classes["form-input-group-checkbox-item"]}
                  >
                    <SecondaryInputCheckbox value={item} />
                  </FlexboxGrid.Item>
                ))}
              </CheckboxGroup>
            </FlexboxGrid>
          </Form.Group>
          <Form.Group
            controlId="how_many_users"
            className={classes["form-input-group"]}
          >
            <SecondaryFormLabel
              text={i18n.t("register_form_mm.how_many_users") as string}
              required={true}
            />
            <SecondaryInputPicker
              name="zoom_user"
              data={HOW_MANY_USERS}
              value={applicationSettings.zoom_user}
              onChange={handleZoomUserSelectInputChange}
              disabled={false}
            />
          </Form.Group>
          <Form.Group
            controlId="company_name"
            className={classes["form-input-group"]}
          >
            <SecondaryFormLabel
              text={i18n.t("register_form_mm.company_name") as string}
              required={true}
            />
            <SecondaryInput
              name="company_name"
              value={values.company_name}
              onChange={handleValueInputChange}
              type="text"
              disabled={false}
              step=""
              placeHolder={
                i18n.t("register_form_mm.samples.company_name") as string
              }
            />
          </Form.Group>
          <FlexboxGrid justify="space-between" style={{ marginBottom: "0px" }}>
            <FlexboxGrid.Item
              colspan={11}
              className={classes["input-name-flexgrid-left"]}
            >
              <Form.Group
                controlId="name"
                className={classes["form-input-group"]}
              >
                <SecondaryFormLabel
                  text={i18n.t("register_form_mm.last_name") as string}
                  required={true}
                />
                <SecondaryInput
                  name="last_name"
                  value={values.last_name}
                  onChange={handleValueInputChange}
                  type="text"
                  disabled={false}
                  step=""
                  placeHolder={
                    i18n.t("register_form_mm.samples.last_name") as string
                  }
                />
              </Form.Group>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item
              colspan={11}
              className={classes["input-name-flexgrid-right"]}
            >
              <Form.Group
                controlId="name"
                className={classes["form-input-group"]}
              >
                <SecondaryFormLabel
                  text={i18n.t("register_form_mm.first_name") as string}
                />
                <SecondaryInput
                  name="first_name"
                  value={values.first_name}
                  onChange={handleValueInputChange}
                  type="text"
                  disabled={false}
                  step=""
                  placeHolder={
                    i18n.t("register_form_mm.samples.first_name") as string
                  }
                />
              </Form.Group>
            </FlexboxGrid.Item>
          </FlexboxGrid>
          <Form.Group
            controlId="number_of_employees"
            className={classes["form-input-group"]}
          >
            <SecondaryFormLabel
              text={i18n.t("register_form_mm.number_of_employees") as string}
              required={true}
            />
            <SecondaryInputPicker
              name="number_of_employees"
              data={NUM_OF_EMPLOYEES_SELECTIONS}
              value={values.number_of_employees}
              onChange={handleNumberOfEmployeesSelectInputChange}
              disabled={false}
            />
          </Form.Group>
          <Form.Group
            controlId="phone_number"
            className={classes["form-input-group"]}
          >
            <SecondaryFormLabel
              text={i18n.t("register_form_mm.phone_number") as string}
              required={true}
            />
            <SecondaryInput
              name="phone_number"
              value={values.phone_number}
              onChange={handleValueInputChange}
              type="phone_number"
              disabled={false}
              step=""
              placeHolder={
                i18n.t("register_form_mm.samples.phone_number") as string
              }
            />
          </Form.Group>
          <Form.Group controlId="email" className={classes["form-input-group"]}>
            <FlexboxGrid className={classes["form-label-group"]}>
              <SecondaryFormLabel
                text={i18n.t("register_form_mm.email") as string}
                required={true}
              />
              <p className={classes["form-label-help-text"]}>
                {i18n.t("register_form_mm.help_text.email") as string}
              </p>
            </FlexboxGrid>
            <SecondaryInput
              name="email"
              value={values.email}
              onChange={handleValueInputChange}
              type="email"
              disabled={false}
              step=""
              placeHolder={i18n.t("register_form_mm.samples.email") as string}
            />
          </Form.Group>
          <Form.Group
            controlId="headquarter"
            className={classes["form-input-group"]}
          >
            <SecondaryFormLabel
              text={i18n.t("register_form_mm.headquarter") as string}
              required={true}
            />
            <SecondaryInput
              name="headquarter"
              value={values.headquarter}
              onChange={handleValueInputChange}
              type="text"
              disabled={false}
              step=""
              placeHolder={
                i18n.t("register_form_mm.samples.headquarter") as string
              }
              rows={3}
            />
          </Form.Group>
          <Form.Group
            controlId="ceo_name"
            className={classes["form-input-group"]}
          >
            <SecondaryFormLabel
              text={i18n.t("register_form_mm.ceo_name") as string}
              required={true}
            />
            <SecondaryInput
              name="ceo_name"
              value={values.ceo_name}
              onChange={handleValueInputChange}
              type="text"
              disabled={false}
              step=""
              placeHolder={
                i18n.t("register_form_mm.samples.ceo_name") as string
              }
            />
          </Form.Group>
          <Form.Group
            controlId="accepted"
            className={classes["form-input-group"]}
          >
            <FlexboxGrid className={classes["accept-checkbox"]}>
              <Checkbox
                name="accepted"
                value={values.accepted}
                onChange={handleCheckInputChange}
                disabled={false}
              >
                <span className={classes["accept-checkbox-text"]}>
                  <a
                    href="https://www.revcomm.co.jp/privacypolicy/"
                    target="_blank"
                    rel="noreferrer noopener"
                    className={classes["accept-checkbox-text-link"]}
                  >
                    個人情報の取り扱い
                  </a>{" "}
                  と{" "}
                  <a
                    href="https://www.revcomm.co.jp/agreement/"
                    target="_blank"
                    rel="noreferrer noopener"
                    className={classes["accept-checkbox-text-link"]}
                  >
                    個人情報保護方針
                  </a>{" "}
                  に同意する
                </span>
              </Checkbox>
            </FlexboxGrid>
          </Form.Group>
          <Form.Group className={classes["form-input-group"]}>
            <ReCAPTCHA
              sitekey="6LdfqOQZAAAAAFe8i_GhcQWu39jG2fcp85xOcKUu"
              onChange={(e: any) => console.log(e)}
              style={{ margin: "0px", justifyContent: "center" }}
            />
          </Form.Group>
          <Form.Group
            controlId="submit"
            className={classes["form-input-group"]}
          >
            <SecondaryButton
              text={"無料トライアルを開始する"}
              onClick={handleSubmit}
              isLoading={isSubmitting}
            />
          </Form.Group>
          {isSubmitting && progressMessage !== "" ? (
            <Form.Group className={classes["form-input-group"]}>
              <Message
                className={classes["message-alert"]}
                showIcon
                type="info"
                header="しばらくお待ち下さい。"
              >
                <p>{progressMessage}</p>
              </Message>
            </Form.Group>
          ) : null}
        </Form>
      </FlexboxGrid>
      <div className={classes["line"]}>
        <div className={classes["line2"]}></div>
      </div>
      <div className={classes["section-right"]}>
        <div className={classes["section-right-frame"]}>
          <div className={classes["section-right-frame-title"]}>
            <div className={classes["section-right-frame-title-top"]}>
              <div className={classes["section-right-frame-title-tips"]}>
                <div className={classes["section-right-frame-title-tips-text"]}>
                  まずはお試し
                </div>
              </div>
              <svg
                className={classes["section-right-frame-title-polygon"]}
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="12"
                viewBox="0 0 15 12"
                fill="none"
              >
                <path
                  className={classes["section-right-frame-title-polygon-svg"]}
                  d="M7.5 12L0.571797 -6.02284e-07L14.4282 -1.81365e-06L7.5 12Z"
                  fill="#FFAC14"
                />
              </svg>
            </div>
            <div className={classes["section-right-frame-title-under"]}>
              <div className={classes["section-right-frame-title-under-text1"]}>
                無料
              </div>
              <div className={classes["section-right-frame-title-under-text2"]}>
                トライアル
              </div>
            </div>
          </div>
          <div className={classes["section-right-frame-img"]}>
            <img src={IMG_MV} alt="イメージ" className={classes["img-mv"]} />
          </div>
          <div className={classes["section-right-frame-functions"]}>
            <p className={classes["section-right-frame-functions-title"]}>
              有償プランと同じ機能が利用可能！
            </p>
            <div className={classes["section-right-frame-functions-frame"]}>
              {FUNCTIONS_LIST.map((columns, index) => {
                return (
                  <div
                    className={
                      classes["section-right-frame-functions-frame-items"]
                    }
                    key={columns[0].label}
                  >
                    {columns.map((item, index) => {
                      return (
                        <FunctionItem
                          item={item.label}
                          key={item.label}
                          isCheck={item.isCheck}
                        />
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
          <div className={classes["section-right-frame-details"]}>
            <div className={classes["section-right-frame-details-frame"]}>
              <ol className={classes["section-right-frame-details-text"]}>
                <li>
                  {i18n.t("register_form_mm.announcement.detail1") as string}
                  <p>
                    {i18n.t("register_form_mm.announcement.detail2") as string}
                  </p>
                </li>
                <li>
                  {i18n.t("register_form_mm.announcement.detail3") as string}
                </li>
                <li>
                  {i18n.t("register_form_mm.announcement.detail4") as string}
                </li>
                <li>
                  {i18n.t("register_form_mm.announcement.detail5") as string}
                </li>
                <li>
                  {i18n.t("register_form_mm.announcement.detail6") as string}
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MiitelMeetingsRegister;
