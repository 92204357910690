import { CountryCode } from "config/BaseConfig";
import { Input as RsuiteInput } from "rsuite";
import classes from "./Input.module.scss";

interface Props {
  name: string;
  value: string | number | readonly string[] | undefined;
  type: string;
  disabled: boolean;
  onChange: (value: any, event: any) => void;
  step: string;
  placeHolder?: string;
  rows?: number;
  componentStyles?: any;
}

const Input = ({
  name,
  value,
  type,
  disabled,
  onChange,
  step = "1",
  placeHolder = "",
  rows,
  componentStyles,
}: Props) => {
  return (
    <RsuiteInput
      className={
        CountryCode === "US" ? classes.main_css_us : classes.main_css_jp
      }
      name={name}
      value={value}
      type={type}
      disabled={disabled}
      onChange={onChange}
      step={step}
      placeholder={placeHolder}
      as={rows ? "textarea" : undefined}
      rows={rows ? rows : undefined}
      style={componentStyles}
    ></RsuiteInput>
  );
};

export default Input;
