import { Elements } from "@stripe/react-stripe-js";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import JPContinuePage from "pages/jp/ContinuousPage";
import Footer from "./components/organisms/Footer";
import SecondaryFooter from "components/organisms/SecondaryFooter";
import Header from "./components/organisms/Header";
import SecondaryHeader from "./components/organisms/SecondaryHeader";
import JPCallback from "./pages/jp/Callback";
import JPCardSetup from "./pages/jp/CardSetup";
import JPContinueOrCancelPage from "./pages/jp/ContinueOrCancelPage";
import JPFinished from "./pages/jp/Finished";
import JPLogin from "./pages/jp/Login";
import JPRegister from "./pages/jp/Register";
import JPUpsellForm from "./pages/jp/Upsell";
import USCardSetup from "./pages/us/CardSetup";
import USFinished from "./pages/us/Finished";
import USRegister from "./pages/us/Register";
import USRegistered from "./pages/us/Registered";
import USSelectFunctions from "./pages/us/SelectFunctions";
import USSelectService from "./pages/us/SelectService";

import { RecoilRoot } from "recoil";
import { CountryCode, DeployPage, stripePromise } from "./config/BaseConfig";

import Error404 from "./pages/Error404";
import Login from "./pages/us/Login";

const JPContinueOrCancelRoutes = () => {
  return (
    <Routes>
      <Route path="/finished" element={<JPFinished />}></Route>
      <Route path="/" element={<JPContinueOrCancelPage />}></Route>
      <Route path="*" element={<JPContinueOrCancelPage />} />
    </Routes>
  );
};

const JPContinueRoutes = () => {
  return (
    <Routes>
      <Route path="/finished" element={<JPFinished />}></Route>
      <Route path="/" element={<JPContinuePage />}></Route>
      <Route path="*" element={<JPContinuePage />} />
    </Routes>
  );
};

const JPRoutes = () => {
  return (
    <Routes>
      <Route path="/payment-setup" element={<JPCardSetup />}></Route>
      <Route path="/finished" element={<JPFinished />}></Route>
      <Route path="/" element={<JPRegister />}></Route>
      <Route path="*" element={<JPRegister />}></Route>
    </Routes>
  );
};

const JPRoutesForTest = () => {
  return (
    <Routes>
      <Route path="/cancel" element={<JPContinueOrCancelPage />}></Route>
      <Route path="/continuous" element={<JPContinuePage />}></Route>
      <Route path="/payment-setup" element={<JPCardSetup />}></Route>
      <Route path="/finished" element={<JPFinished />}></Route>
      <Route path="/order/add" element={<JPUpsellForm />}></Route>
      <Route path="/" element={<JPRegister />}></Route>
      <Route path="*" element={<JPRegister />}></Route>
    </Routes>
  );
};

const USRoutes = () => {
  return (
    <Routes>
      <Route path="/error" element={<Error404 />}></Route>
      <Route path="/login" element={<Login />}></Route>
      <Route path="/registered" element={<USRegistered />}></Route>
      <Route path="/select-service" element={<USSelectService />}></Route>
      <Route path="/select-functions" element={<USSelectFunctions />}></Route>
      <Route path="/card-setup" element={<USCardSetup />}></Route>
      <Route path="/finished" element={<USFinished />}></Route>
      <Route path="*" element={<USRegister />}></Route>
    </Routes>
  );
};

const UPSellRoutes = () => {
  return (
    <Routes>
      <Route path="/add" element={<JPUpsellForm />}></Route>
    </Routes>
  );
};


const App = () => {
  return (
    <div id="main_content" style={{ position: "absolute", width: "100%" }}>
      <RecoilRoot>
        <Router basename={"/"}>
          <Routes>
            <Route path="/login" element={<JPLogin />}></Route>
            <Route path="/callback" element={<JPCallback />}></Route>
          </Routes>
        </Router>
        <Router
          basename={
            CountryCode === "JP"
              ? DeployPage === undefined
                ? "/jp/"
                : DeployPage === "APPLICATION_FORM"
                ? "/jp/trial/meetings/"
                : DeployPage === "DOCUMENT_REQUEST_MM"
                ? "/jp/document-request-meeting/"
                : DeployPage === "DOCUMENT_REQUEST_CC"
                ? "/jp/document-request-cc/"
                : DeployPage === "DOCUMENT_REQUEST_RECPOD"
                ? "/jp/document-request-recpod/"
                : DeployPage === "DEMO_REQUEST_CC"
                ? "/jp/demo-request-cc/"
                : DeployPage === "DEMO_REQUEST"
                ? "/jp/demo-request/"
                : DeployPage === "CANCEL_FORM"
                ? "/jp/trial/cancel/"
                : DeployPage === "UPSELL"
                ? "/jp/order/"
                : DeployPage === "CONTINUOUS_FORM"
                ? "/jp/trial/continuous/"
                : "/jp/"
              : "/"
          }
        >
          <Elements stripe={stripePromise}>
            {DeployPage === "APPLICATION_FORM" || DeployPage === undefined ? (
              <SecondaryHeader />
            ) : (
              <Header />
            )}
            {CountryCode === "JP" ? (
              DeployPage === undefined ? (
                <div
                  style={{
                    backgroundColor: "#FFFFFF",
                  }}
                >
                  <JPRoutesForTest />
                </div>
              ) : DeployPage === "CANCEL_FORM" ? (
                <div
                  style={{
                    backgroundColor: "#FFFFFF",
                  }}
                >
                  <JPContinueOrCancelRoutes />
                </div>

              ) : DeployPage === "UPSELL" ? (
                <div
                  style={{
                    backgroundColor: "#FFFFFF",
                  }}
                >
                  <UPSellRoutes />
                  </div>
              ) : DeployPage === "CONTINUOUS_FORM" ? (
                <div
                  style={{
                    backgroundColor: "#FFFFFF",
                  }}
                >
                  <JPContinueRoutes />
                </div>
              ) : (
                <div
                  style={{
                    backgroundColor: "#FFFFFF",
                  }}
                >
                  <JPRoutes />
                </div>
              )
            ) : CountryCode === "US" ? (
              <USRoutes />
            ) : null}
            {DeployPage === "APPLICATION_FORM" || DeployPage === undefined ? (
              <SecondaryFooter />
            ) : (
              <Footer />
            )}
          </Elements>
        </Router>
      </RecoilRoot>
    </div>
  );
};

export default App;
