import { useState } from "react";
import { ValueType as RsuiteCheckBoxType } from "rsuite/esm/Checkbox";

import { ToolsType } from "pages/jp/MiitelMeetingsRegister/type";

export type ValuesEvent = CommonValuesEvent | Partial<SpecificValuesEvent>;
type CommonValuesEvent = string | number | readonly string[];
type SpecificValuesEvent = RsuiteCheckBoxType[];

export type SetValuesEvent = (values: ValuesEvent, name: string) => void;

export type ValueChangeHandler = (
  value: string | number | readonly string[],
  event: React.SyntheticEvent<Element, Event>
) => void;

type GenericChangeHandler = <
  T extends (RsuiteCheckBoxType | undefined) | RsuiteCheckBoxType[]
>(
  value: T,
  eventOrChecked: T extends RsuiteCheckBoxType[]
    ? React.SyntheticEvent<Element, Event>
    : boolean | React.SyntheticEvent<Element, Event>,
  event?: React.SyntheticEvent<Element, Event>
) => void | undefined;

export type CommonFormValueType = {
  company_name: string;
  phone_number: string;
  email: string;
  accepted: string;
};

export const useFormValuesChange = <T extends CommonFormValueType>(
  initialValues: T
) => {
  const [values, setValues] = useState<T>(initialValues as T);

  const setValuesEvent: SetValuesEvent = (value, name) =>
    setValues((prev) => {
      return { ...prev, [name]: value };
    });

  const handleValueInputChange: ValueChangeHandler = (value, event) => {
    const name: string | null = event.currentTarget.getAttribute("name");
    if (name == null || !Object.keys(values).includes(name)) {
      return;
    }
    setValues({ ...values, [name]: value.toString() });
  };

  const handleCheckInputChange: GenericChangeHandler = (
    value,
    checked,
    event
  ) => {
    if (event === undefined) {
      if ("meeting_tools" in values) {
        const checked_values = value as ToolsType[];
        setValues({ ...values, meeting_tools: checked_values });
      }
      return;
    }
    const name: string | null = event.currentTarget.getAttribute("name");
    if (name == null || value === undefined) {
      return;
    }
    let str_value: string = value.toString();
    if (name === "accepted") {
      if (values.accepted === "0") {
        str_value = "1";
      } else {
        str_value = "0";
      }
    }
    setValues({ ...values, [name]: str_value });
  };

  return {
    values,
    setValuesEvent,
    handleValueInputChange,
    handleCheckInputChange,
  };
};
