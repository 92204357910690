import { CountryCode } from "config/BaseConfig";
import { InputGroup } from "rsuite";
import InputNumber from "../../atoms/InputNumber";
import classes from "./InputNumberGroup.module.scss";

const componentStyles = {};

interface Props {
  name: string;
  value: number;
  disabled: boolean;
  onChange: (
    value: string | number | readonly string[],
    event: React.SyntheticEvent<Element, Event>
  ) => void;
  handleMinus: (target: string) => void;
  handlePlus: (target: string) => void;
}

const InputNumberGroup = ({
  name,
  value,
  disabled,
  onChange,
  handleMinus,
  handlePlus,
}: Props) => {
  return (
    <InputGroup
      className={
        CountryCode === "US" ? classes.main_css_us : classes.main_css_jp
      }
    >
      {disabled ? null : (
        <InputGroup.Button
          style={componentStyles}
          disabled={disabled}
          hidden={disabled}
          onClick={() => handleMinus(name)}
        >
          -
        </InputGroup.Button>
      )}
      <InputNumber
        name={name}
        value={value}
        disabled={disabled}
        onChange={onChange}
      />
      {disabled ? null : (
        <InputGroup.Button
          style={componentStyles}
          disabled={disabled}
          hidden={disabled}
          onClick={() => handlePlus(name)}
        >
          +
        </InputGroup.Button>
      )}
    </InputGroup>
  );
};

export default InputNumberGroup;
