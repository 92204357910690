import FormHeader from "components/atoms/FormHeader";
import SubHeader from "components/atoms/SubHeader";
import { FlexboxGrid } from "rsuite";

type Props = {
  sub_header: string;
  header: string;
};

const FormHeaderGroup = ({ sub_header, header }: Props) => {
  return (
    <FlexboxGrid
      justify="center"
      style={{
        width: "100%",
      }}
    >
      <FlexboxGrid.Item
        colspan={24}
        style={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <SubHeader text={sub_header} />
      </FlexboxGrid.Item>
      {sub_header !== "" ? (
        <FlexboxGrid.Item
          colspan={24}
          style={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            maxWidth: "50px",
            border: "none",
            borderBottom: "5px solid",
            borderImage: "linear-gradient(to right, #219EFE 0%, #38D9D2 100%)",
            borderImageSlice: 1,
            margin: 0,
            paddingBlock: "10px",
          }}
        ></FlexboxGrid.Item>
      ) : null}
      <FlexboxGrid.Item
        colspan={24}
        style={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          paddingTop: "10px",
        }}
      >
        <FormHeader text={header} />
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
};

export default FormHeaderGroup;
