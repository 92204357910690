import { CountryCode } from "config/BaseConfig";
import { Checkbox } from "rsuite";
import classes from "./InputCheckbox.module.scss";

interface Props {
  value: string | undefined;
}

const InputCheckbox = ({ value }: Props) => {
  return (
    <Checkbox
      className={
        CountryCode === "US" ? classes.main_css_us : classes.main_css_jp
      }
      key={value}
      value={value}
    >
      {value}
    </Checkbox>
  );
};

export default InputCheckbox;
