import { CardElement, CardNumberElement } from "@stripe/react-stripe-js";
import axios from "axios";
import { ConsoleAPIBaseURL } from "../../config/BaseConfig";
import ConsoleAPIClient from "../../service/utils/ConsoleAPIClient";

export default class StripeService {
  static baseURL = ConsoleAPIBaseURL;
  static headers = {
    Authorization: "JWT " + localStorage.getItem("access_token"),
    "Content-type": "application/json",
  };

  static async getSetupIntent(token: string) {
    const data = {
      setup_intent: {
        stripe_customer_id: token,
      },
    };
    return await ConsoleAPIClient.postWithoutAuth(
      "/api/v1/cards/create-setup-intent",
      data
    );
  }

  static async confirmCardSetup(stripe: any, elements: any, token: string) {
    const cardNumberElement = elements.getElement(CardNumberElement);
    const clientSecret = await this.getSetupIntent(token);
    const result = await stripe.confirmCardSetup(
      clientSecret.data.setup_intent.client_secret,
      {
        payment_method: {
          card: cardNumberElement,
          billing_details: {
            name: "",
          },
        },
      }
    );
    if (result.error) {
      console.log("[error]", result.error);
    } else {
      return result;
    }
  }

  static async setMainPaymentMethod(token: string, paymentMethodId: string) {
    const data = {
      token: token,
      payment_method_id: paymentMethodId,
    };
    return await ConsoleAPIClient.postWithoutAuth(
      "/api/v1/card/set-main-payment-method",
      data
    );
  }

  static async getPaymentMethods(token: string) {
    return await ConsoleAPIClient.getWithoutAuth(
      "/api/v1/card/list?token=" + token
    );
  }

  static async detachPaymentMethods(token: string, paymentMethodId: string) {
    const data = {
      token: token,
      payment_method_id: paymentMethodId,
    };
    return await ConsoleAPIClient.postWithoutAuth("/api/v1/card/detach", data);
  }

  static async createPaymentMethod(stripe: any, elements: any) {
    const cardElement = elements.getElement(CardElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      console.log("[error]", error);
    } else {
      console.log("[PaymentMethod]", paymentMethod);
      return paymentMethod;
    }
  }

  static async createSubscription(paymentMethodId: string, priceId: string) {
    try {
      const res = await axios.post(
        this.baseURL + "account/api/v1/create-subscription/",
        {
          paymentMethodId: paymentMethodId,
          priceId: priceId,
        },
        { headers: this.headers }
      );
      return res;
    } catch (e) {
      document.location.href = "/";
      throw e;
    }
  }

  static async checkHasSubscription() {
    return await ConsoleAPIClient.getWithAuth(
      "/account/api/v1/check-has-subscription/"
    );
  }
}
