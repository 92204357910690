import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

export type ApplicationSettings = {
  contractType: "yearly_contract";
  full_user: number;
  view_only_user: number;
  zoom_user: number;
  tenant_code: string;
  customer_id: string;
  email: string;
};

export const applicationSettingsState = atom<ApplicationSettings>({
  key: "applicationSettings",
  default: {
    contractType: "yearly_contract",
    full_user: 0,
    view_only_user: 0,
    zoom_user: 0,
    tenant_code: "",
    customer_id: "",
    email: "",
  },
  effects_UNSTABLE: [persistAtom],
});
