import { CountryCode } from "config/BaseConfig";
import { Form } from "rsuite";
import RequiredLabel from "../RequiredLabel";
import classes from "./FormLabel.module.scss";

interface Props {
  text: string;
  required?: boolean;
  style?: React.CSSProperties;
}

const FormLabel = ({ text, required, style = {}}: Props) => {
  const hasCustomStyle = Object.keys(style).length > 0;
  return (
    <Form.ControlLabel
      style={hasCustomStyle ? style : undefined}
      className={
        CountryCode === "US" ? classes.main_css_us : classes.main_css_jp
      }
    >
      {text}
      {required && <RequiredLabel />}
    </Form.ControlLabel>
  );
};

export default FormLabel;
