import React, { useCallback, useState } from 'react';
import DatePicker, { registerLocale } from "react-datepicker";

import { Helmet } from 'react-helmet';
import { FlexboxGrid, Form, Message } from 'rsuite';

import FormHeaderGroup from "components/molecules/FormHeaderGroup";
import FormLabel from "../../../components/atoms/FormLabel";
import Input from "../../../components/atoms/Input";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import Button from "components/atoms/Button";
import i18n from "i18next";
import classes from "./RegisterPage.module.scss";
import "react-datepicker/dist/react-datepicker.css";
import { ConsoleAPIBaseURL, ConsoleAPIKey } from "config/BaseConfig";
import ja from 'date-fns/locale/ja';


const validateEmail = (email: string) => {
  // メールの形式が正しいかをチェックする正規表現
  const ok_pattern = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  return ok_pattern.test(email);
};

const isNumericOrEmpty = (value: string) => {

  if (value === "") {
    return true;
  }
  const numericPattern = /^[0-9]+$/;
  return numericPattern.test(value);
};

interface FormValues {
  company_name: string;
  tenant_code: string;
  user_name: string;
  mail_address: string;
  preferred_date: string;
  products: {
    full_id: string;
    view_only_id: string;
    mobile_option: string;
    change_view_only_to_full_id: string;
    change_full_to_view_only_id: string;
  };
  phone_numbers: {
    phone_number_03: string;
    phone_number_050: string;
    phone_number_06: string;
    phone_number_0120: string;
    phone_number_0800: string;
  };
  other: string;
  accepted: string;
}

const UpsellForm = () => {
  // フォームの状態を管理するためのステート
  const [values, setValues] = useState<FormValues>({
    company_name: '', // 貴社名
    tenant_code: '', // テナント名（企業ID）
    user_name: '', // ご担当者名
    mail_address: '', // メールアドレス
    preferred_date: '', // ご希望の変更日
    products: {
      full_id: '', // 【追加】MiiTel フル機能ID(解析付)のID数
      view_only_id: '', // 【追加】MiiTel Analytics 閲覧専用IDのID数
      mobile_option: '', // 【追加】MiiTel モバイルオプションの数量
      change_view_only_to_full_id: '', // 【変更】MiiTel Analytics閲覧専用IDからフル機能IDへの変更数
      change_full_to_view_only_id: '', // 【変更】MiiTel フル機能IDから閲覧専用IDへの変更数
    },
    phone_numbers: {
      phone_number_03: '', // 【追加】電話番号(03番号)の数量
      phone_number_050: '', // 【追加】電話番号(050番号)の数量
      phone_number_06: '', // 【追加】電話番号(06番号)の数量
      phone_number_0120: '', // 【追加】電話番号(0120番号)の数量
      phone_number_0800: '', // 【追加】電話番号(0800番号)の数量
    },
    other: '', // その他の要件
    accepted: '', // 個人情報の取り扱いに同意する
  });
  const [progressMessage, setProgressMessage] = useState<string>("");
  const [errors, setErrors] = useState<string[]>([]);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  // 入力変更をハンドルする関数
  const handleInputChange = (value: string, event: React.ChangeEvent<HTMLInputElement>) => {

    const { name } = event.target;
    if (name.includes('.')) {
      const [objectName, propertyName] = name.split('.');

      setValues((prevValues: FormValues) => ({
        ...prevValues,
        [objectName]: {
          ...((prevValues[objectName as keyof FormValues] as any) || {}),
          [propertyName]: value
        }
      }));
    } else {
      setValues((prevValues: FormValues) => ({
        ...prevValues,
        [name]: value
      }));
    }
  };

  const handleDateChange = (date: string) => {
    setValues({ ...values, preferred_date: date });
  };

  const navigate = useNavigate();

  const convertToIntegerOrZero = (value: string): number | null => {
    return value === '' ? 0 : parseInt(value, 10);
  };

  const convertEmptyToNull = (obj: any): any => {
    const newObj: {[key: string]: any} = {};
    Object.keys(obj).forEach(key => {
      if (key === 'products' || key === 'phone_numbers') {
        newObj[key] = {};
        Object.keys(obj[key]).forEach(subKey => {
          newObj[key][subKey] = convertToIntegerOrZero(obj[key][subKey]);
        });
      } else if (typeof obj[key] === 'object' && obj[key] !== null) {
        newObj[key] = convertEmptyToNull(obj[key]);
      } else {
        if (key === 'preferred_date') {
          newObj[key] = obj[key].slice(0, 10);
        } else {
          newObj[key] = obj[key];
        }

      }
    });
    newObj['accepted'] = true;
    return newObj;
  };

  // フォーム送信処理
  const handleSubmit = useCallback(async () => {
    const newErrors = [];
    if (!values.company_name) {
      newErrors.push(i18n.t("upsell_form.errors.company_name_required"));
    }
    if (!values.tenant_code) {
      newErrors.push(i18n.t("upsell_form.errors.tenant_code_required"));
    }
    if (!values.user_name) {
      newErrors.push(i18n.t("upsell_form.errors.user_name_required"));
    }
    if (!values.mail_address) {
      newErrors.push(i18n.t("upsell_form.errors.mail_address_required"));
    }
    if (!validateEmail(values.mail_address)) {
      newErrors.push(i18n.t("upsell_form.errors.mail_address_format_error"));
    }
    if (!values.preferred_date) {
      newErrors.push(i18n.t("upsell_form.errors.preferred_date_required"));
    }
    if (!isNumericOrEmpty(values.products.full_id)) {
      newErrors.push(i18n.t("upsell_form.errors.full_id_number_only"));
    }
    if (!isNumericOrEmpty(values.products.view_only_id)) {
      newErrors.push(i18n.t("upsell_form.errors.view_only_id_number_only"));
    }
    if (!isNumericOrEmpty(values.products.mobile_option)) {
      newErrors.push(i18n.t("upsell_form.errors.mobile_option_number_only"));
    }
    if (!isNumericOrEmpty(values.products.change_view_only_to_full_id)) {
      newErrors.push(i18n.t("upsell_form.errors.change_view_only_to_full_id_number_only"));
    }
    if (!isNumericOrEmpty(values.products.change_full_to_view_only_id)) {
      newErrors.push(i18n.t("upsell_form.errors.change_full_to_view_only_id_number_only"));
    }
    if (!isNumericOrEmpty(values.phone_numbers.phone_number_03)) {
      newErrors.push(i18n.t("upsell_form.errors.phone_number_03_number_only"));
    }
    if (!isNumericOrEmpty(values.phone_numbers.phone_number_050)) {
      newErrors.push(i18n.t("upsell_form.errors.phone_number_050_number_only"));
    }
    if (!isNumericOrEmpty(values.phone_numbers.phone_number_06)) {
      newErrors.push(i18n.t("upsell_form.errors.phone_number_06_number_only"));
    }
    if (!isNumericOrEmpty(values.phone_numbers.phone_number_0120)) {
      newErrors.push(i18n.t("upsell_form.errors.phone_number_0120_number_only"));
    }
    if (!isNumericOrEmpty(values.phone_numbers.phone_number_0800)) {
      newErrors.push(i18n.t("upsell_form.errors.phone_number_0800_number_only"));
    }

    setErrors(newErrors);

    if (newErrors.length > 0) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      setIsSubmitting(false);
      return;
    }
    setIsSubmitting(true);

    const formData = convertEmptyToNull(values);


    try {
      const response = await fetch(ConsoleAPIBaseURL + 'api/jp/v1/products/add', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "x-api-key": ConsoleAPIKey,
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setProgressMessage("");
        navigate("/finished?pageType=document-request");

      } else {
        const data = await response.json();
        console.error('Submission failed:', data);
      }
    } catch (error) {
      console.error('Submission failed:', error);
    } finally {
      setIsSubmitting(false);
    }
  }, [values]);

  registerLocale('ja', ja);

  return (
    <div>
      <Helmet>
        <title>MiiTel 追加注文フォーム</title>
        <meta name="description" content="MiiTel 追加注文フォーム" />
      </Helmet>
      <div
        style={{
          width: "100%",
          height: "auto",
          display:"flex",
        }}
      ></div>
      <FlexboxGrid
        justify="center"
        style={{
          paddingBlock: "20px",
          display:"flex",
        }}
        className={classes["campaign-overview"]}
      >
      <FlexboxGrid
          justify="center"
          style={{
            width: "100%",
          }}
        >
      <FormHeaderGroup sub_header="" header="MiiTel 追加注文" />
          <FlexboxGrid.Item
            className={classes["campaign-content-flexgrid"]}
            colspan={24}
          >
            <div>
              <FlexboxGrid>
              <FlexboxGrid.Item
                  className={classes["campaign-list-title"]}
                  colspan={24}
                >
                  {i18n.t("upsell_form.summary") as string}
                  </FlexboxGrid.Item>
                <FlexboxGrid.Item
                  className={classes["campaign-list-normal"]}
                  colspan={24}
                >
                  {i18n.t("upsell_form.explanation1") as string}
                </FlexboxGrid.Item>
                <FlexboxGrid.Item
                  className={classes["campaign-list-normal"]}
                  colspan={24}
                ></FlexboxGrid.Item>
                <FlexboxGrid.Item
                  className={classes["campaign-list-normal"]}
                  colspan={24}
                >
                  {i18n.t("upsell_form.explanation2") as string}
                </FlexboxGrid.Item>
              </FlexboxGrid>

              <FlexboxGrid>
              <FlexboxGrid.Item
                  className={classes["campaign-list-title"]}
                  colspan={24}
                >
                  {i18n.t("upsell_form.explanation3") as string}
                </FlexboxGrid.Item>
                <FlexboxGrid.Item
                  className={classes["campaign-list-normal"]}
                  colspan={24}
                >
                  {i18n.t("upsell_form.explanation4") as string}
                </FlexboxGrid.Item>
                <FlexboxGrid.Item
                  className={classes["campaign-list-normal"]}
                  colspan={24}
                >
                  {i18n.t("upsell_form.explanation5") as string}
                </FlexboxGrid.Item>
                <FlexboxGrid.Item
                  className={classes["campaign-list-normal"]}
                  colspan={24}
                >
                  {i18n.t("upsell_form.explanation6") as string}
              </FlexboxGrid.Item>
              <FlexboxGrid.Item
                  className={classes["campaign-list-normal"]}
                  colspan={24}
                >
                  {i18n.t("upsell_form.explanation7") as string}
                </FlexboxGrid.Item>
                <FlexboxGrid.Item
                  className={classes["campaign-list-normal"]}
                  colspan={24}
                >
                  {i18n.t("upsell_form.explanation8") as string}
                </FlexboxGrid.Item>
              </FlexboxGrid>
            </div>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </FlexboxGrid>
      <FlexboxGrid
          justify="center"
          style={{
            width: "100%",
          }}
        >
          <FormHeaderGroup
            sub_header={""}
            header={
              "申し込みフォーム"
            }
          />
          <FlexboxGrid.Item
            style={{
              paddingTop: "25px",
              display: "flex",
              justifyContent: "center",
            }}
            colspan={24}
          >
            <Form className={classes["form-content-flexgrid"]}>
              {errors.length > 0 ? (
                <Message
                  className={classes["message-alert"]}
                  showIcon
                  type="error"
                  header="Error"
                >
                  {errors.map((error, idx) => {
                    return <p key={idx}>{error}</p>;
                  })}
                </Message>
              ) : null}
              <Form.Group
                controlId="company_name"
                style={{ paddingBlock: "20px", marginBottom: "0px" }}
              >
                <FormLabel
                  text={i18n.t("upsell_form.company_name")}
                />

                <Input
                  name="company_name"
                  value={values.company_name}
                  onChange={handleInputChange}
                  type="text"
                  disabled={false}
                  step=""
                  placeHolder={i18n.t("upsell_form.input_placeholder")}
                />
              </Form.Group>
              <Form.Group
                controlId="tenant_code"
                style={{ paddingBlock: "20px", marginBottom: "0px" }}
              >
                <FormLabel
                  text={i18n.t("upsell_form.tenant_code")}
                />
                <FlexboxGrid.Item
                  className={classes["campaign-list-normal"]}
                  colspan={24}
                >
                  *テナント名がわからない場合は、MiiTel Analytics の右上に表示されるアカウントアイコン、または過去に受け取られた請求書からご確認いただけます。
                </FlexboxGrid.Item>
                <Input
                  name="tenant_code"
                  value={values.tenant_code}
                  onChange={handleInputChange}
                  type="text"
                  disabled={false}
                  step=""
                  placeHolder={i18n.t("upsell_form.input_placeholder")}
                />
              </Form.Group>
              <Form.Group
                controlId="user_name"
                style={{ paddingBlock: "20px", marginBottom: "0px" }}
              >
                <FormLabel
                  text={i18n.t("upsell_form.user_name") as string}
                />
                <Input
                  name="user_name"
                  value={values.user_name}
                  onChange={handleInputChange}
                  type="text"
                  disabled={false}
                  step=""
                  placeHolder={i18n.t("upsell_form.input_placeholder")}
                />
              </Form.Group>
              <Form.Group
                controlId="mail_address"
                style={{ paddingBlock: "20px", marginBottom: "0px" }}
              >
                <FormLabel
                  text={i18n.t("upsell_form.mail_address")}
                />
                <Input
                  name="mail_address"
                  value={values.mail_address}
                  onChange={handleInputChange}
                  type="text"
                  disabled={false}
                  step=""
                  placeHolder={i18n.t("upsell_form.input_placeholder")}
                />
              </Form.Group>
              <Form.Group
                controlId="preferred_date"
                style={{ paddingBlock: "20px", marginBottom: "0px" }}
              >
                <FormLabel
                  text={i18n.t("upsell_form.preferred_date")}
                />
                <FlexboxGrid.Item
                  className={classes["campaign-list-normal"]}
                  colspan={24}
                >
                  *当フォームを入力頂いてから、ご提供までに3営業日頂戴致します。
                </FlexboxGrid.Item>
                <DatePicker
                  selected={values.preferred_date ? new Date(values.preferred_date) : null}
                  onChange={(date: Date) => handleDateChange(date.toISOString())}

                  dateFormat="yyyy-MM-dd"
                  locale="ja"
                  className="Input_main_css_jp__UcWES rs-input"
                  placeholderText={i18n.t("upsell_form.input_placeholder_date")}
                />
              </Form.Group>
              <Form.Group
                controlId="full_id"
                style={{ paddingBlock: "20px", marginBottom: "0px" }}
              >
                <FormLabel
                  style={{ marginBottom: "20px" }}
                  text={i18n.t("upsell_form.full_id")}
                />

                <Input
                  name="products.full_id"
                  value={values.products.full_id}
                  onChange={handleInputChange}
                  type="text"
                  disabled={false}
                  step=""
                  placeHolder={i18n.t("upsell_form.input_placeholder")}
                />
              </Form.Group>
              <Form.Group
                controlId="view_only_id"
                style={{ paddingBlock: "20px", marginBottom: "0px" }}
              >
                <FormLabel
                  style={{ marginBottom: "20px" }}
                  text={i18n.t("upsell_form.view_only_id")}
                />

                <Input
                  name="products.view_only_id"
                  value={values.products.view_only_id}
                  onChange={handleInputChange}
                  type="text"
                  disabled={false}
                  step=""
                  placeHolder={i18n.t("upsell_form.input_placeholder")}
                />
              </Form.Group>
              <Form.Group
                controlId="mobile_option"
                style={{ paddingBlock: "20px", marginBottom: "0px" }}
              >
                <FormLabel
                  text={i18n.t("upsell_form.mobile_option")}
                />
                <FlexboxGrid.Item
                  className={classes["campaign-list-normal"]}
                  colspan={24}
                >
                </FlexboxGrid.Item>
                <Input
                  name="products.mobile_option"
                  value={values.products.mobile_option}
                  onChange={handleInputChange}
                  type="text"
                  disabled={false}
                  step=""
                  placeHolder={i18n.t("upsell_form.input_placeholder")}
                />
              </Form.Group>
              <Form.Group
                controlId="change_view_only_to_full_id"
                style={{ paddingBlock: "20px", marginBottom: "0px" }}
              >
                <FormLabel
                  style={{ marginBottom: "40px" }}
                  text={i18n.t("upsell_form.change_view_only_to_full_id")}
                />
                <Input
                  name="products.change_view_only_to_full_id"
                  value={values.products.change_view_only_to_full_id}
                  onChange={handleInputChange}
                  type="text"
                  disabled={false}
                  step=""
                  placeHolder={i18n.t("upsell_form.input_placeholder")}
                />
              </Form.Group>
              <Form.Group
                controlId="change_full_to_view_only_id"
                style={{ paddingBlock: "20px", marginBottom: "0px" }}
              >
                <FormLabel
                  style={{ marginBottom: "40px" }}
                  text={i18n.t("upsell_form.change_full_to_view_only_id")}
                />
                <Input
                  name="products.change_full_to_view_only_id"
                  value={values.products.change_full_to_view_only_id}
                  onChange={handleInputChange}
                  type="text"
                  disabled={false}
                  step=""
                  placeHolder={i18n.t("upsell_form.input_placeholder")}
                />
              </Form.Group>
              <Form.Group
                controlId="phone_numbers.phone_number_03"
                style={{ paddingBlock: "20px", marginBottom: "0px" }}
              >
                <FormLabel
                  style={{ marginBottom: "20px" }}
                  text={i18n.t("upsell_form.phone_number_03")}
                />
                <Input
                  name="phone_numbers.phone_number_03"
                  value={values.phone_numbers.phone_number_03}
                  onChange={handleInputChange}
                  type="text"
                  disabled={false}
                  step=""
                  placeHolder={i18n.t("upsell_form.input_placeholder")}
                />
              </Form.Group>
              <Form.Group
                controlId="phone_number_050"
                style={{ paddingBlock: "20px", marginBottom: "0px" }}
              >
                <FormLabel
                  style={{ marginBottom: "20px" }}
                  text={i18n.t("upsell_form.phone_number_050")}
                />
                <Input
                  name="phone_numbers.phone_number_050"
                  value={values.phone_numbers.phone_number_050}
                  onChange={handleInputChange}
                  type="text"
                  disabled={false}
                  step=""
                  placeHolder={i18n.t("upsell_form.input_placeholder")}
                />
              </Form.Group>
              <Form.Group
                controlId="phone_number_06"
                style={{ paddingBlock: "20px", marginBottom: "0px" }}
              >
                <FormLabel
                  style={{ marginBottom: "20px" }}
                  text={i18n.t("upsell_form.phone_number_06")}
                />
                <Input
                  name="phone_numbers.phone_number_06"
                  value={values.phone_numbers.phone_number_06}
                  onChange={handleInputChange}
                  type="text"
                  disabled={false}
                  step=""
                  placeHolder={i18n.t("upsell_form.input_placeholder")}
                />
              </Form.Group>
              <Form.Group
                controlId="phone_number_0120"
                style={{ paddingBlock: "20px", marginBottom: "0px" }}
              >
                <FormLabel
                  style={{ marginBottom: "20px" }}
                  text={i18n.t("upsell_form.phone_number_0120")}
                />
                <Input
                  name="phone_numbers.phone_number_0120"
                  value={values.phone_numbers.phone_number_0120}
                  onChange={handleInputChange}
                  type="text"
                  disabled={false}
                  step=""
                  placeHolder={i18n.t("upsell_form.input_placeholder")}
                />
              </Form.Group>
              <Form.Group
                controlId="phone_number_0800"
                style={{ paddingBlock: "20px", marginBottom: "0px" }}
              >
                <FormLabel
                  style={{ marginBottom: "20px" }}
                  text={i18n.t("upsell_form.phone_number_0800")}
                />

                <Input
                  name="phone_numbers.phone_number_0800"
                  value={values.phone_numbers.phone_number_0800}
                  onChange={handleInputChange}
                  type="text"
                  disabled={false}
                  step=""
                  placeHolder={i18n.t("upsell_form.input_placeholder")}
                />
              </Form.Group>
              <Form.Group
                controlId="other"
                style={{ paddingBlock: "20px", marginBottom: "0px" }}
              >
                <FormLabel
                  text={i18n.t("upsell_form.other")}
                />
                <Input
                  name="other"
                  value={values.other}
                  onChange={handleInputChange}
                  type="text"
                  rows={5}
                  disabled={false}
                  step=""
                  placeHolder={i18n.t("upsell_form.input_placeholder")}
                />
              </Form.Group>


                <Form.Group>
                  <FlexboxGrid>
                    <FlexboxGrid.Item
                      colspan={4}
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                        height: "22.84px",
                        paddingRight: "10px",
                      }}
                    >
                      <Input
                        name="accepted"
                        value={values.accepted}
                        onChange={handleInputChange}
                        type="checkbox"
                        disabled={false}
                        step=""
                        componentStyles={{ height: "16px", width: "16px" }}
                      />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item
                      style={{
                        fontSize: "16px",
                      }}
                      colspan={20}
                    >
                      <a
                        href="https://www.revcomm.co.jp/terms/"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        個人情報の取り扱い
                      </a>{" "}
                      と{" "}
                      <a
                        href="https://www.revcomm.co.jp/privacypolicy/"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        個人情報保護方針
                      </a>{" "}
                      に同意する
                    </FlexboxGrid.Item>
                  </FlexboxGrid>
                </Form.Group>


              <Form.Group
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <ReCAPTCHA
                  sitekey="6LdfqOQZAAAAAFe8i_GhcQWu39jG2fcp85xOcKUu"
                  onChange={(e: any) => console.log(e)}
                />
              </Form.Group>

              {isSubmitting && progressMessage !== "" ? (
                <Form.Group>
                  <Message
                    className={classes["message-alert"]}
                    showIcon
                    type="info"
                    header="しばらくお待ち下さい。"
                  >
                    <p>{progressMessage}</p>
                  </Message>
                </Form.Group>
              ) : null}

              <Form.Group
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <Button
                  text={"下記に同意し入力内容を送信"}
                  onClick={handleSubmit}
                  isLoading={isSubmitting}
                />
              </Form.Group>


                <Form.Group
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <a
                    href="https://www.revcomm.co.jp/terms/"
                    target="_blank"
                    rel="noreferrer noopener"
                    style={{ color: "#209CFF" }}
                  >
                    個人情報の取り扱い
                  </a>
                  <p style={{ paddingInline: "5px" }}>/</p>
                  <a
                    href="https://www.revcomm.co.jp/privacypolicy/"
                    target="_blank"
                    rel="noreferrer noopener"
                    style={{ color: "#209CFF" }}
                  >
                    個人情報保護方針
                  </a>
                </Form.Group>
            </Form>
          </FlexboxGrid.Item>
        </FlexboxGrid>
    </div>
  );
};

export default UpsellForm;