import { DeployPage } from "config/BaseConfig";

export enum PodCode {
  DEFAULT = "default",
  TYO101 = "tyo101",
  OGN101 = "ogn101",
}

export const NG_TENANT_CODES = [
  "admin",
  "console",
  "account",
  "simulator",
  "payment",
  "registration",
  "testcall",
  "speedtest",
  "pbx",
  "api",
  "zoom-integration",
  "google-meet-integration",
  "phone",
  "video-integration",
  "developer",
  "developers",
  // {pod_code}-{env}。例 ogn101-dev, tyo101-stg など
  ...Object.values(PodCode)
    .filter((code) => code !== PodCode.DEFAULT)
    .map((code) => `${code}-dev`),
  ...Object.values(PodCode)
    .filter((code) => code !== PodCode.DEFAULT)
    .map((code) => `${code}-stg`),
  ...Object.values(PodCode)
    .filter((code) => code !== PodCode.DEFAULT)
    .map((code) => `${code}-prod`),
];

export const NG_TENANT_CODE_PATTERNS = [
  /^.*miitel.*$/,
  /^.*-api$/,
  /^.*softphone.*$/,
  /^.*e2e.*$/,
  /^.*revcomm.*$/,
  /^.*demo.*$/,
  /^ogn.*$/,
  /^tyo.*$/,
  /^jkt.*$/,
];

export const isValidTenantCode = (input: string): boolean => {
  const pattern = /^[a-z0-9-]+$/;
  if (!pattern.test(input)) {
    return false;
  }

  // NG文字列と一致するかチェック
  if (NG_TENANT_CODES.includes(input)) {
    return false;
  }

  // NG正規表現と一致するかチェック
  for (const ng_tenant_pattern of NG_TENANT_CODE_PATTERNS) {
    if (ng_tenant_pattern.test(input)) {
      return false;
    }
  }
  return true;
};

export const isValidResponseTenantCode = (tenantCode: string): boolean => {
  const NGIncludeTenantCode = ["revcomm", "miitel", "pbx", "api"];
  const NGEquivalentTenantCode = [
    "softphone",
    "e2e",
    "developer",
    "demo",
    "developers",
  ];
  return (
    !NGIncludeTenantCode.some((NGcode) => tenantCode.includes(NGcode)) &&
    !NGEquivalentTenantCode.includes(tenantCode)
  );
};

export const isValidEmail = (email: string) => {
  // メールの形式が正しいかをチェックする正規表現
  const ok_pattern = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  return ok_pattern.test(email);
};