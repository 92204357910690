import i18n from "i18next";
import React from "react";
import { Button, Panel } from "rsuite";

interface PlanProps {
  subscription_id: string;
  handleSelect(
    subscription_id: string,
    selected_subscription_name: string
  ): any;
  hidden: boolean;
  contentImg: any;
  header: string;
  description: string;
  width?: number;
  canUse?: boolean;
}

const Plan: React.FC<PlanProps> = ({
  subscription_id,
  handleSelect,
  hidden,
  contentImg,
  header,
  description,
  width = 200,
  canUse = true,
}) => {
  return (
    <Panel
      hidden={hidden}
      shaded
      bordered
      bodyFill
      style={{
        display: "inline-block",
        width: 320,
        minHeight: 320,
        background: canUse ? "#FFFFFF" : "#F8F9FA",
      }}
    >
      <div
        style={{
          width: "320px",
          height: "168px",
          display: "flex",
          justifyContent: "center",
          borderBottom: "solid 1px #DADEE5",
        }}
      >
        <img alt="" src={contentImg} width={width} />
      </div>
      <Panel style={{ background: canUse ? "#FFFFFF" : "#F8F9FA" }}>
        <p style={{ paddingBottom: "10px" }}>{description}</p>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "24px",
          }}
        >
          <Button
            style={{
              color: canUse ? "#FFFFFF" : "#A0A9B5",
              height: "36px",
              background: canUse ? "#1764E9" : "#E8F0FC",
              border: canUse ? "1px solid #1764E9" : "1px solid #F8F9FA",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              borderRadius: "4px",
              minWidth: "272px",
            }}
            onClick={() => handleSelect(subscription_id, header)}
          >
            {i18n.t("services.select_plan") as string}
          </Button>
        </div>
      </Panel>
    </Panel>
  );
};

export default Plan;
