import { useState, useCallback } from "react";
import i18n from "i18next"; // i18nのインポートが必要です

// カスタムフックの定義
export const useErrorMessages = () => {
  const [errors, setErrors] = useState<string[]>([]);

  const showErrorMessage = useCallback((errorMessages: string[] = []) => {
    if (errorMessages.length > 0) {
      setErrors(errorMessages);
    } else {
      setErrors((prevErrors) => [
        ...prevErrors,
        i18n.t("register_form_mm.error.messages.default") as string, // default error message
      ]);
    }
  }, []);

  // Clear error messages
  const clearErrors = useCallback(() => {
    setErrors([]);
  }, []);

  return { errors, showErrorMessage, clearErrors };
};
