import Button from "components/atoms/Button/Button";
import InputCheckbox from "components/atoms/InputCheckbox/InputCheckbox";
import FormHeaderGroup from "components/molecules/FormHeaderGroup";
import usePageTracking from "hooks/useTracking";
import i18n from "i18next";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  CheckboxGroup,
  Col,
  FlexboxGrid,
  Form,
  Grid,
  Message,
  Row,
} from "rsuite";
import { ValueType } from "rsuite/esm/Checkbox";
import FormLabel from "../../../components/atoms/FormLabel";
import Input from "../../../components/atoms/Input";
import InputNumberGroup from "../../../components/molecules/InputNumberGroup";
import { useApplicationSettings } from "../../../hooks/useApplicationSettings";
import ConsoleAPIClient from "../../../service/utils/ConsoleAPIClient";
import { applicationSettingsState } from "../../../stores/applicationSettings";
import { accountIdState, leadIdState } from "../../../stores/customerSettings";
import classes from "./CardSetupPage.module.scss";

import { Helmet } from "react-helmet";

interface Props {}

export enum PodCode {
  DEFAULT = "default",
  TYO101 = "tyo101",
  OGN101 = "ogn101",
}

const CardSetup: React.FC<Props> = () => {
  useEffect(() => {
    document.title =
      "トライアルプラン申し込み | MiiTel Meetings（ミーテルミーティング）";
  }, []);

  const NG_TENANT_CODES = [
    "admin",
    "console",
    "account",
    "simulator",
    "payment",
    "registration",
    "testcall",
    "speedtest",
    "pbx",
    "api",
    "zoom-integration",
    "google-meet-integration",
    "phone",
    "video-integration",
    // {pod_code}-{env}。例 ogn101-dev, tyo101-stg など
    ...Object.values(PodCode)
      .filter((code) => code !== PodCode.DEFAULT)
      .map((code) => `${code}-dev`),
    ...Object.values(PodCode)
      .filter((code) => code !== PodCode.DEFAULT)
      .map((code) => `${code}-stg`),
    ...Object.values(PodCode)
      .filter((code) => code !== PodCode.DEFAULT)
      .map((code) => `${code}-prod`),
  ];
  const NG_TENANT_CODE_PATTERNS = [
    /^.*miitel.*$/,
    /^.*-api$/,
    /^.*softphone.*$/,
    /^.*e2e.*$/,
    /^.*revcomm.*$/,
    /^.*demo.*$/,
    /^ogn.*$/,
    /^tyo.*$/,
    /^jkt.*$/,
  ];

  const search = useLocation().search;
  usePageTracking();

  const {
    setApplicationSettingsEvent,
    handleInputChange,
    handleMinus,
    handlePlus,
  } = useApplicationSettings();
  const applicationSettings = useRecoilValue(applicationSettingsState);
  const [leadId, setLeadId] = useRecoilState(leadIdState);
  const [accountId, setAccountId] = useRecoilState(accountIdState);
  const navigate = useNavigate();
  const [values, setValues] = useState({
    headquarter: "",
    ceo_name: "",
    meeting_tools: [],
  });
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [progressMessage, setProgressMessage] = useState<string>("");
  const [errors, setErrors] = useState<string[]>([]);

  const isValidInput = (input: string): boolean => {
    const pattern = /^[a-z0-9-]+$/;
    if (!pattern.test(input)) {
      return false;
    }

    // NG文字列と一致するかチェック
    if (NG_TENANT_CODES.includes(input)) {
      return false;
    }

    // NG正規表現と一致するかチェック
    for (const ng_tenant_pattern of NG_TENANT_CODE_PATTERNS) {
      if (ng_tenant_pattern.test(input)) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    const query = new URLSearchParams(search);
    const token = query.get("token");
    if (token) {
      (async () => {
        const token_response = await ConsoleAPIClient.postWithoutAuth(
          "/api/jp/v1/tokens/decode",
          {
            token: {
              token: token,
            },
          }
        );
        setLeadId(token_response.data.token.lead_id);
        setAccountId(token_response.data.token.account_id);
        setIsEditable(true);
        navigate("/payment-setup");
      })();
    } else if (leadId === "") {
      navigate("/");
    }
  }, [
    leadId,
    navigate,
    search,
    setAccountId,
    setApplicationSettingsEvent,
    setLeadId,
    setIsEditable,
  ]);

  const setValuesEvent = (
    value: string | number | readonly string[] | ValueType[],
    name: string
  ) => {
    const obj = {
      ...values,
      [name]: value,
    };
    setValues(obj);
  };

  const handleValueInputChange = (
    value: string | number | readonly string[],
    event: React.SyntheticEvent<Element, Event>
  ) => {
    const name: string | null = event.currentTarget.getAttribute("name");
    if (name == null) {
      return;
    }
    const str_value = value.toString();
    setValues({ ...values, [name]: str_value });
  };

  // Checkbox
  const data = ["Zoom", "Microsoft Teams", "Google Meet", "その他"];
  const handleCheckBoxChange = (value: ValueType[]) => {
    setValuesEvent(value, "meeting_tools");
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    setErrors([]);
    if (
      !values.ceo_name ||
      !values.headquarter ||
      !applicationSettings.tenant_code ||
      applicationSettings.zoom_user === 0 ||
      values.meeting_tools.length === 0
    ) {
      setErrors((errors) => [...errors, "全ての項目を入力してください。"]);
      window.scrollTo({ top: 0, behavior: "smooth" });
      setIsSubmitting(false);
      return;
    }

    if (
      applicationSettings.tenant_code.length < 4 ||
      applicationSettings.tenant_code.length > 33 ||
      !isValidInput(applicationSettings.tenant_code)
    ) {
      setErrors((errors) => [
        ...errors,
        "テナントコードは 5 文字以上、 32 文字以下のアルファベットの小文字およびハイフンでご入力ください。",
      ]);
      window.scrollTo({ top: 0, behavior: "smooth" });
      setIsSubmitting(false);
      return;
    }

    // Check if tenant_code is already used
    const tenant_response = await ConsoleAPIClient.postWithoutAuth(
      "/api/common/v1/tenants",
      {
        tenant: {
          tenant_code: applicationSettings.tenant_code,
        },
      }
    );
    setProgressMessage("テナントコードをチェックしています。");
    if (
      tenant_response.data.tenant.is_exist ||
      applicationSettings.tenant_code.includes("miitel") ||
      applicationSettings.tenant_code.includes("pbx") ||
      applicationSettings.tenant_code.includes("api") ||
      applicationSettings.tenant_code === "console" ||
      applicationSettings.tenant_code === "speedtest" ||
      applicationSettings.tenant_code === "testcall" ||
      applicationSettings.tenant_code === "admin" ||
      applicationSettings.tenant_code === "payment" ||
      applicationSettings.tenant_code === "registration" ||
      applicationSettings.tenant_code === "simulator"
    ) {
      setErrors((errors) => [
        ...errors,
        "そのテナントコードは使えません。他のテナントコードを指定してください。",
      ]);
      window.scrollTo({ top: 0, behavior: "smooth" });
      setIsSubmitting(false);
      return;
    }

    try {
      const newMeetingTools: string[] = values.meeting_tools.map((item) => {
        if (item === "Zoom") {
          return "ZOOM";
        } else if (item === "Microsoft Teams") {
          return "teams";
        }
        return item;
      });
      setProgressMessage("テナントおよびお客様基本情報を登録しています。");
      const lc_response = await ConsoleAPIClient.postWithoutAuth(
        "/api/jp/v1/leads",
        {
          lead: {
            id: leadId,
            account_id: accountId,
            tenant_code: applicationSettings.tenant_code,
            headquarter: values.headquarter,
            ceo_name: values.ceo_name,
          },
        }
      );

      setAccountId(lc_response.data.converted_lead.account_id);

      // Finish Registration
      setProgressMessage("完了処理を実施しています。");
      await ConsoleAPIClient.postWithoutAuth("/api/jp/v1/registrations", {
        registration: {
          email: lc_response.data.converted_lead.email,
          account_id: lc_response.data.converted_lead.account_id,
          department_id: lc_response.data.converted_lead.department_id,
          tenant_code: applicationSettings.tenant_code,
          full_user: applicationSettings.full_user,
          view_only_user: applicationSettings.view_only_user,
          zoom_user: applicationSettings.zoom_user,
          headquarter: values.headquarter,
          ceo_name: values.ceo_name,
          meeting_tools: newMeetingTools.join(";"),
        },
      });

      setProgressMessage("");
      navigate("/finished?pageType=application");
      return;
    } catch {
      setErrors((errors) => [
        ...errors,
        "エラーが発生しました。お手数ですが、お問い合わせください。",
      ]);
      window.scrollTo({ top: 0, behavior: "smooth" });
      setProgressMessage("");
      setIsSubmitting(false);
      return;
    }
  };

  return (
    <div>
      <Helmet>
        <meta
          name="description"
          content={
            "MiiTel Meetingsのトライアル申し込みページです。AIによる文字起こしと議事録作成、トーク分析機能など、実際の使い心地をお試しいただけます。"
          }
        />
      </Helmet>
      <FlexboxGrid justify="center" style={{ paddingBlock: "60px" }}>
        <FlexboxGrid
          justify="center"
          style={{
            width: "100%",
          }}
        >
          <FormHeaderGroup sub_header="" header="トライアル開始フォーム" />
          <FlexboxGrid.Item
            colspan={24}
            style={{
              paddingTop: "25px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Form className={classes["form-content-flexgrid"]}>
              {errors.length > 0 ? (
                <Message
                  className={classes["message-alert"]}
                  showIcon
                  type="error"
                  header="Error"
                >
                  {errors.map((error, idx) => {
                    return <p key={idx}>{error}</p>;
                  })}
                </Message>
              ) : null}
              <Form.Group controlId="tenant_code">
                <FormLabel
                  text={i18n.t("functions.tenant_code") as string}
                  required={true}
                />
                <Input
                  name="tenant_code"
                  value={applicationSettings.tenant_code}
                  onChange={handleInputChange}
                  type="tenant_code"
                  disabled={false}
                  step=""
                  placeHolder={
                    i18n.t("functions.samples.tenant_code") as string
                  }
                />
                <Form.HelpText>
                  {i18n.t("functions.tenant_code_description1") as string}
                  <br></br>
                  {i18n.t("functions.tenant_code_description2") as string}
                </Form.HelpText>
              </Form.Group>
              <Form.Group
                controlId="ceo_name"
                style={{ paddingBlock: "20px", marginBottom: "0px" }}
              >
                <FormLabel
                  text={i18n.t("card_setup.ceo_name") as string}
                  required={true}
                />
                <Input
                  name="ceo_name"
                  value={values.ceo_name}
                  onChange={handleValueInputChange}
                  type="text"
                  disabled={false}
                  step=""
                  placeHolder={i18n.t("card_setup.samples.ceo_name") as string}
                />
              </Form.Group>
              <Form.Group
                controlId="company_name"
                style={{ paddingBlock: "20px", marginBottom: "0px" }}
              >
                <FormLabel
                  text={i18n.t("card_setup.headquarter") as string}
                  required={true}
                />
                <Input
                  name="headquarter"
                  value={values.headquarter}
                  onChange={handleValueInputChange}
                  type="text"
                  disabled={false}
                  step=""
                  placeHolder={
                    i18n.t("card_setup.samples.headquarter") as string
                  }
                  rows={3}
                />
              </Form.Group>
              <Form.Group
                controlId="meeting_tools"
                style={{
                  paddingBlock: "20px",
                  marginBottom: "0px",
                }}
              >
                <FormLabel
                  text={i18n.t("register_form.meeting_tools") as string}
                  required={true}
                />

                {/* TODO: Move to atom component */}
                <CheckboxGroup
                  inline
                  name="checkboxList"
                  value={values.meeting_tools}
                  onChange={handleCheckBoxChange}
                  style={{ backgroundColor: "#f7f7fa", marginLeft: "0px" }}
                  className="rs-flex-box-grid rs-flex-box-grid-top rs-flex-box-grid-start"
                >
                  {data.map((item) => (
                    <FlexboxGrid.Item key={item} colspan={24}>
                      <InputCheckbox value={item} />
                    </FlexboxGrid.Item>
                  ))}
                </CheckboxGroup>
              </Form.Group>
              <Form.Group
                controlId="zoom_user"
                style={{ paddingBlock: "20px", marginBottom: "0px" }}
              >
                <FormLabel
                  text={i18n.t("functions.zoom_user_amount") as string}
                  required={true}
                />
                <InputNumberGroup
                  name="zoom_user"
                  value={applicationSettings.zoom_user}
                  onChange={handleInputChange}
                  disabled={!isEditable}
                  handleMinus={handleMinus}
                  handlePlus={handlePlus}
                />
              </Form.Group>
              {isSubmitting && progressMessage !== "" ? (
                <Form.Group>
                  <Message
                    className={classes["message-alert"]}
                    showIcon
                    type="info"
                    header="しばらくお待ち下さい。"
                  >
                    <p>{progressMessage}</p>
                  </Message>
                </Form.Group>
              ) : null}
              <Form.Group>
                <Grid fluid>
                  <Row>
                    <Col
                      xs={24}
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      <Button
                        text={i18n.t("card_setup.submit") as string}
                        isLoading={isSubmitting}
                        onClick={() => handleSubmit()}
                      />
                    </Col>
                  </Row>
                </Grid>
              </Form.Group>
            </Form>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </FlexboxGrid>
    </div>
  );
};

export default CardSetup;
