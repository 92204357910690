import { CountryCode } from "config/BaseConfig";
import { InputPicker as RsuiteInputPicker } from "rsuite";
import classes from "./InputPicker.module.scss";

interface Props {
  name: string;
  value: string | number | readonly string[] | undefined;
  data: any;
  onChange: any;
  disabled: boolean;
}

const InputPicker = ({ name, value, data, onChange, disabled }: Props) => {
  return (
    <RsuiteInputPicker
      className={
        CountryCode === "US"
          ? classes.main_css_us + " " + name
          : classes.main_css_jp + " " + name
      }
      name={name}
      value={value}
      data={data}
      disabled={disabled}
      onChange={onChange}
      cleanable={false}
      searchable={false}
      placeholder="選択してください"
    ></RsuiteInputPicker>
  );
};

export default InputPicker;
