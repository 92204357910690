export default class URLService {
  static createURL(base, limit, offset, other) {
    const searchParams = new URLSearchParams()
    if (limit !== null && limit !== undefined && limit !== '') {
      const l = Number(limit)
      searchParams.append('limit', isNaN(l) ? '' : l)
    }
    if (offset !== null && offset !== undefined && offset !== '') {
      const o = Number(offset)
      searchParams.append('offset', isNaN(o) ? '' : o)
    }
    for (var k in other) {
      if (other[k]) {
        searchParams.append(k, other[k] ? other[k] : '')
      }
    }
    return base + '?' + searchParams.toString()
  }

  static getParam(name, url) {
    if (!url) {
      url = window.location.href
    }
    name = name.replace(/[[\]]/g, '\\$&')
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url)
    if (!results) {
      return null
    }
    if (!results[2]) {
      return ''
    }
    return decodeURIComponent(results[2].replace(/\+/g, ' '))
  }

  static joinURL = (...args) => {
    return args
      .join('/')
      .replace(/[/]+/g, '/')
      .replace(/^(.+):\//, '$1://')
      .replace(/^file:/, 'file:/')
      .replace(/\/(\?|&|#[^!])/g, '$1')
      .replace(/\?/g, '&')
      .replace('&', '?')
  }
}
