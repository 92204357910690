import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

export const leadIdState = atom<string>({
  key: "leadId",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

export const accountIdState = atom<string>({
  key: "accountId",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

export const stripeCustomerIdState = atom<string>({
  key: "stripeCustomerId",
  default: "",
  effects_UNSTABLE: [persistAtom],
});
