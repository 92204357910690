import { useRecoilState } from "recoil";
import { applicationSettingsState } from "../stores/applicationSettings";

export const useApplicationSettings = () => {
  const [applicationSettings, setApplicationSettings] = useRecoilState(
    applicationSettingsState
  );

  const setApplicationSettingsEvent = (
    value: string | number | readonly string[],
    name: string
  ) => {
    const obj = {
      ...applicationSettings,
      [name]: value,
    };
    setApplicationSettings(obj);
  };

  const handleInputChange = (
    value: string | number | readonly string[],
    event: React.SyntheticEvent<Element, Event>
  ) => {
    const name: string | null = event.currentTarget.getAttribute("name");
    if (name == null) {
      return;
    }
    setApplicationSettingsEvent(value, name);
  };

  const handleZoomUserSelectInputChange = (
    value: string | number | readonly string[],
    _: React.SyntheticEvent<Element, Event>
  ) => {
    setApplicationSettingsEvent(value, "zoom_user");
  };

  const handleMinus = (target: string) => {
    if (
      target === "full_user" ||
      target === "view_only_user" ||
      target === "zoom_user"
    ) {
      const nextValue = Number(applicationSettings[target]) - 1;
      if (nextValue < 1) {
        return;
      }
      setApplicationSettingsEvent(nextValue, target);
    }
  };

  const handlePlus = (target: string) => {
    if (
      target === "full_user" ||
      target === "view_only_user" ||
      target === "zoom_user"
    ) {
      const nextValue = Number(applicationSettings[target]) + 1;
      setApplicationSettingsEvent(nextValue, target);
    }
  };

  return {
    setApplicationSettings,
    setApplicationSettingsEvent,
    handleInputChange,
    handleZoomUserSelectInputChange,
    handleMinus,
    handlePlus,
  };
};
