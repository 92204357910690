/**
 * @description 日付を15分単位に丸める
 * @param Date
 * @returns Date
 */
export const roundToNearest15Minutes = (date: Date): Date => {
  // 15分単位に丸める処理
  const minutes = date.getMinutes();
  const remainder = minutes % 15;
  const roundedMinutes = remainder === 0 ? minutes : minutes + 15 - remainder;
  const roundedDate = new Date(date);
  roundedDate.setMinutes(roundedMinutes, 0, 0); // 分, 秒, ミリ秒を設定
  return roundedDate;
};
