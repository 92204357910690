import classes from "./FunctionItem.module.scss";

import { CountryCode } from "config/BaseConfig";
import VectorLogo from "../../../assets/img/Vector.svg";

interface FunctionItemProps {
  item: string;
  isCheck?: boolean;
}

const FunctionItem = ({ item, isCheck }: FunctionItemProps) => {
  return (
    <div className={classes["main_css_" + CountryCode]}>
      {isCheck ? (
        <>
          <img
            src={VectorLogo}
            alt="Vector"
            className={classes["main_css_svg"]}
          />
          <p className={classes["main_css_text"]}>{item}</p>
        </>
      ) : (
        <p className={classes["main_css_text"]}>{item}</p>
      )}
    </div>
  );
};
export default FunctionItem;
