import { loadStripe } from "@stripe/stripe-js";

type Environment = "local" | "development" | "staging" | "production";
type Prefix =
  | "REACT_APP_LOCAL_"
  | "REACT_APP_DEVELOPMENT_"
  | "REACT_APP_STAGING_"
  | "REACT_APP_PRODUCTION_";

export const env: Environment = process.env.REACT_APP_BUILD_ENV as Environment;

const getPrefix = (env: Environment) =>
  `REACT_APP_${env.toUpperCase()}_` as Prefix;

const prefix: Prefix = getPrefix(env);

export const ConsoleAPIBaseURL = process.env[
  prefix + "CONSOLE_BASE_API_URL"
] as string;
export const ConsoleAPIKey = process.env[prefix + "CONSOLE_API_KEY"] as string;
export const StripePublicKey = process.env[
  prefix + "STRIPE_PUBLIC_KEY"
] as string;
export const CountryCode = process.env[prefix + "COUNTRY_CODE"] as string;
export const DeployPage = process.env[prefix + "DEPLOY_PAGE"] as string;
export const stripePromise = loadStripe(StripePublicKey);

export const AuthData = {
  region: process.env[prefix + "COGNITO_REGION"],
  userPoolId: process.env[prefix + "COGNITO_USER_POOL_ID"],
  userPoolWebClientId: process.env[prefix + "COGNITO_WEB_CLIENT_ID"],
  oauth: {
    domain: process.env[prefix + "COGNITO_WEB_DOMAIN"],
    scope: [
      "profile",
      "email",
      "openid",
      "aws.cognito.signin.user.admin",
      "phone",
    ],
    redirectSignIn: process.env[prefix + "COGNITO_REDIRECT_URL_SIGNIN"],
    redirectSignOut: process.env[prefix + "COGNITO_REDIRECT_URL_SIGNOUT"],
    responseType: "code",
  },
};

export const DataDogClientToken = process.env[
  prefix + "DATADOG_CLIENT_TOKEN"
] as string;
export const DataDogServiceName = process.env[
  prefix + "DATADOG_SERVICE_NAME"
] as string;
