import { CountryCode } from "config/BaseConfig";
import React from "react";
import { Col, FlexboxGrid } from "rsuite";
import classes from "./Footer.module.scss";

interface FooterProps {}

const Footer: React.FC<FooterProps> = () => {
  return (
    <FlexboxGrid className={classes["main_css_" + CountryCode]}>
      <FlexboxGrid.Item as={Col} colspan={24} md={14}>
        <FlexboxGrid
          style={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            height: "94px",
          }}
        >
          <FlexboxGrid.Item
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
            as={Col}
            colspan={24}
            md={8}
          >
            <a
              className={classes["a_css_" + CountryCode]}
              href="https://www.revcomm.co.jp/"
            >
              運営会社
            </a>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
            as={Col}
            colspan={24}
            md={8}
          >
            <a
              className={classes["a_css_" + CountryCode]}
              href="https://www.revcomm.co.jp/terms/"
            >
              ご利用規約
            </a>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
            as={Col}
            colspan={24}
            md={8}
          >
            <a
              className={classes["a_css_" + CountryCode]}
              href="https://www.revcomm.co.jp/privacypolicy/"
            >
              プライバシーポリシー
            </a>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </FlexboxGrid.Item>
      <FlexboxGrid.Item as={Col} colspan={24} md={10}>
        <FlexboxGrid
          style={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            height: "94px",
          }}
        >
          <a
            className={classes["a_css_" + CountryCode]}
            href="https://miitel.com/jp/company/"
          >
            @ RevComm Inc.
          </a>
        </FlexboxGrid>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
};

export default Footer;
