import i18n from "i18next";
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FlexboxGrid, Form, Panel } from "rsuite";
import Button from "../../../components/atoms/Button";
import FormLabel from "../../../components/atoms/FormLabel";
import Input from "../../../components/atoms/Input";
import Steps from "../../../components/molecules/Steps";
import ConsoleAPIClient from "../../../service/utils/ConsoleAPIClient";

const validateEmail = (email: string) => {
  const ok_pattern = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  return ok_pattern.test(email);
};

interface RegisterProps {}

const Register: React.FC<RegisterProps> = () => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [values, setValues] = useState({
    company_name: "",
    name: "",
    email: "",
    accepted: "0",
  });

  const handleSubmit = useCallback(async () => {
    setIsSubmitting(true);
    if (!validateEmail(values.email)) {
      setIsSubmitting(false);
      return;
    }
    try {
      await ConsoleAPIClient.postWithoutAuth("/api/us/v1/customers", {
        customer: {
          company_name: values.company_name,
          name: values.name,
          email: values.email,
        },
      });
      navigate("/registered");
    } catch {
      setIsSubmitting(false);
    }
  }, [navigate, values]);

  function handleInputChange(
    value: string | number | readonly string[],
    event: React.SyntheticEvent<Element, Event>
  ) {
    const name: string | null = event.currentTarget.getAttribute("name");
    if (name == null) {
      return;
    }
    if (name === "accepted") {
      if (values.accepted === "0") {
        var str_value: string = "1";
      } else {
        str_value = "0";
      }
    } else {
      str_value = value.toString();
    }
    setValues({ ...values, [name]: str_value });
  }

  return (
    <>
      <Steps activeStep={1} />
      <FlexboxGrid justify="center">
        <FlexboxGrid.Item
          style={{
            paddingTop: "25px",
            display: "flex",
            justifyContent: "center",
          }}
          colspan={24}
        >
          <Panel shaded header={i18n.t("register_form.title") as string}>
            <Form
              style={{
                maxWidth: "500px",
              }}
            >
              <Form.Group controlId="company_name">
                <FormLabel
                  text={i18n.t("register_form.company_name") as string}
                />
                <Input
                  name="company_name"
                  value={values.company_name}
                  onChange={handleInputChange}
                  type="text"
                  disabled={false}
                  step=""
                  placeHolder={
                    i18n.t("register_form.samples.company_name") as string
                  }
                />
              </Form.Group>
              <Form.Group controlId="name">
                <FormLabel text={i18n.t("register_form.username") as string} />
                <Input
                  name="name"
                  value={values.name}
                  onChange={handleInputChange}
                  type="text"
                  disabled={false}
                  step=""
                  placeHolder={
                    i18n.t("register_form.samples.username") as string
                  }
                />
              </Form.Group>
              <Form.Group controlId="email">
                <FormLabel text={i18n.t("register_form.email") as string} />
                <Input
                  name="email"
                  value={values.email}
                  onChange={handleInputChange}
                  type="email"
                  disabled={false}
                  step=""
                  placeHolder={i18n.t("register_form.samples.email") as string}
                />
              </Form.Group>
              <Form.Group>
                <p
                  style={{
                    fontWeight: "700",
                    paddingBottom: "20px",
                  }}
                >
                  {i18n.t("register_form.need_to_agree") as string}
                </p>
                <FlexboxGrid>
                  <FlexboxGrid.Item colspan={2}>
                    <Input
                      name="accepted"
                      value={values.accepted}
                      onChange={handleInputChange}
                      type="checkbox"
                      disabled={false}
                      step=""
                    />
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item style={{}} colspan={22}>
                    I accept the{" "}
                    <a
                      href="https://www.revcomm.co.jp/terms-engilsh/"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Term of Services
                    </a>{" "}
                    and{" "}
                    <a
                      href="https://www.revcomm.co.jp/privacypolicy-engilsh/"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Privacy Policy
                    </a>
                  </FlexboxGrid.Item>
                </FlexboxGrid>
              </Form.Group>

              <Form.Group
                style={{
                  alignItems: "center",
                  justifyContent: "flex-end",
                  display: "flex",
                }}
              >
                <Button
                  text={i18n.t("common.next") as string}
                  onClick={handleSubmit}
                  isLoading={isSubmitting}
                />
              </Form.Group>
            </Form>
          </Panel>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </>
  );
};

export default Register;
