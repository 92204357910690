import { CommonFormValueType } from "hooks/useFormValuesChange";

export type ToolsType = "Zoom" | "Microsoft Teams" | "Google Meet" | "その他";

export type FormValueType = CommonFormValueType &
  Required<SpecificFormValueType>;

type SpecificFormValueType = {
  first_name: string;
  last_name: string;
  number_of_employees: string;
  meeting_tools: ToolsType[];
  ceo_name: string;
  headquarter: string;
  is_miitel_user?: string;
};

export type IsTenantCodeExistRequest = {
  tenant: {
    tenant_code: string;
  };
};

export type IsTenantCodeExistResponse = {
  tenant: {
    is_exist: boolean;
  };
};

export type IsDeniedEmailRequest = {
  mail_check_request: {
    email: string;
  };
};

export type IsDeniedEmailResponse = {
  mail_check_result: {
    is_denied: boolean;
  };
};

export type IsCompetitorRequest = {
  competitors_check_request: {
    company_name: string;
  };
};

export type IsCompetitorResponse = {
  competitors_check_result: {
    is_competitor: boolean;
    is_alliance: boolean;
  };
};

type ToolsMapping = Record<ToolsType, string>;
// Salesforceのリードオブジェクトの「オンラインMTGツールの種類」項目に合わせる
export const toolsMapping: ToolsMapping = {
  Zoom: "ZOOM",
  "Microsoft Teams": "teams",
  "Google Meet": "Google Meet",
  その他: "その他",
};

export type CustomerCreateRequest = {
  customer: {
    company_name: string;
    first_name: string;
    last_name: string;
    email: string;
    title: string;
    phone_number: string;
    how_many_users: number;
    number_of_employees: string;
    meeting_tools: string;
    utm_source: string;
    utm_medium: string;
    utm_campaign: string;
    utm_content: string;
    is_document_request_cc: boolean;
    is_document_request_recpod: boolean;
    is_online_application: boolean;
    tenant_code: string;
    zoom_user: number;
    headquarter: string;
    ceo_name: string;
    is_miitel_user: string;
  };
};

export type CustomerCreateResponse = {
  customer: {
    id: string;
    email: string;
    has_contact: boolean;
    has_contract: boolean;
  };
};
