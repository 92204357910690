import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DeployPage } from "config/BaseConfig";
import usePageTracking from "hooks/useTracking";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { FlexboxGrid } from "rsuite";
import { accountIdState, leadIdState } from "../../../stores/customerSettings";
import classes from "./Finished.module.scss";
import IMG_TRIAL_CHECK_EMAIL from "../../../assets/img/Trial_Check Email.png";

interface Props {}

const Finished: React.FC<Props> = () => {
  const setLeadId = useSetRecoilState(leadIdState);
  const setAccountId = useSetRecoilState(accountIdState);
  const [pageType, setPageType] = useState("application");
  const search = useLocation().search;

  usePageTracking();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setLeadId("");
    setAccountId("");
    const query = new URLSearchParams(search);
    const queryPageType = query.get("pageType");
    if (
      queryPageType === "document-request" ||
      queryPageType === "large-company-application" ||
      queryPageType === "continuous" ||
      queryPageType === "application" ||
      queryPageType === "demo-request"
    ) {
      setPageType(queryPageType);
    }
  }, [search, setAccountId, setLeadId]);

  return (
    <>
      {DeployPage === "APPLICATION_FORM" || pageType === "application" ? (
        <FlexboxGrid className={classes["container"]}>
          <FlexboxGrid.Item className={classes["title-container"]}>
            <h3 className={classes["title-h3"]}>
              お申し込みありがとうございます
            </h3>
            <h2 className={classes["title-h2"]}>メールをご確認ください</h2>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item className={classes["img-container"]}>
            <FlexboxGrid.Item className={classes["img-content"]}>
              <img
                src={IMG_TRIAL_CHECK_EMAIL}
                alt="イメージ"
                className={classes["trial-check-email-img"]}
              />
            </FlexboxGrid.Item>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item className={classes["sentence-container"]}>
            <p className={classes["sentence"]}>
              ご登録いただいたメールアドレスに
              <span className={classes["inline-block"]}>
                ご利用までの流れを送信しました。
              </span>
              <p>メールのご確認をお願いいたします。</p>
            </p>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      ) : (
        <FlexboxGrid justify="center">
          <FlexboxGrid.Item
            colspan={24}
            style={{
              paddingTop: "25px",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              height: "90vh",
            }}
          >
            <div
              style={{
                maxWidth: "600px",
              }}
            >
              <FlexboxGrid
                justify="center"
                style={{ color: "#0089D0", paddingTop: "53px" }}
              >
                <FontAwesomeIcon icon={faCheckCircle} size="6x" />
              </FlexboxGrid>
              <FlexboxGrid justify="center">
                <p className="thank-you-title" style={{ color: "#00548e" }}>
                  Thank you!
                </p>
              </FlexboxGrid>
              <FlexboxGrid justify="center">
                <p className="thank-you-message" style={{ color: "#00548e" }}>
                  {DeployPage === "CANCEL_FORM" ? (
                    <>
                      申請を受け付けました。
                      <br />
                      <br />
                      内容をご登録させていただいたメールアドレスにお送りいたしました。
                      <br />
                      <br />
                      担当者よりご連絡差し上げる場合がございますので、ご対応いただけますと幸いです。
                      <br />
                      <br />
                      今後とも、 MiiTel をよろしくお願いいたします。
                    </>
                  ) : DeployPage === "CONTINUOUS_FORM" ||
                    pageType === "continuous" ? (
                    <>
                      <span>申請ありがとうございました。</span>
                      <br />
                      <br />
                      <span>＜ご利用を継続される場合＞</span>
                      <br />
                      見積等の確認のため、担当者よりご連絡を差し上げます。
                      <br />
                      ご対応のほど、よろしくお願いいたします。
                      <br />
                      <br />
                      <span>＜ご利用を終了される場合＞</span>
                      <br />
                      ご回答ありがとうございました。
                      <br />
                      今後、御社のビジネス課題にマッチするタイミングがございましたら、改めてご検討いただけますと幸いです。
                      <br />
                      <br />
                      <span>
                        今後とも、 RevComm をよろしくお願いいたします。
                      </span>
                      <br />
                    </>
                  ) : pageType === "application" ? (
                    <>
                      MiiTel
                      Meetingsのトライアルプランにお申し込みいただき、誠にありがとうございます。
                      <br />
                      <br />
                      登録いただきましたメールアドレスに「【MiiTel
                      Meetings】利用規約確認のお願い」と記載されたメールをお送りしました。
                      <br />
                      <br />
                      利用規約ご確認後に利用可能となりますので、メールの内容に従い進行いただけますようお願いいたします。
                    </>
                  ) : pageType === "large-company-application" ? (
                    <>
                      MiiTel
                      Meetingsのトライアルプランにお申し込みいただき、誠にありがとうございます。
                      <br />
                      <br />
                      登録いただきましたメールアドレスに「【MiiTel
                      Meetings】利用規約確認のお願い」と記載されたメールをお送りしました。
                      <br />
                      <br />
                      利用規約ご確認後に利用可能となりますので、メールの内容に従い進行いただけますようお願いいたします。
                    </>
                  ) : pageType === "demo-request" ? (
                    <>
                      この度はデモを希望いただきありがとうございます。
                      <br />
                      ご登録いただいた連絡先宛に弊社担当者よりご連絡させていただきます。
                      <br />
                      恐れ入りますが、担当者からの連絡をお待ちいただくようお願いいたします。
                    </>
                  ) : (
                    <>
                      この度は資料請求いただきありがとうございます。ご入力いただいたメールアドレス宛に受付確認メールをお送りしましたのでご確認ください。
                      <br />
                      <br />
                      確認メールが届いていない場合には、メールアドレスが誤っているか、確認メールが迷惑メールフォルダ等に振り分けられている可能性がありますので、再度ご確認をお願いいたします。
                    </>
                  )}
                </p>
              </FlexboxGrid>
            </div>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      )}
    </>
  );
};

export default Finished;
