import i18n from "i18next";
import React from "react";

interface Props {}

const RequiredLabel: React.FC<Props> = () => {
  return (
    <span
      style={{
        padding: "3px 6px",
        marginLeft: "1em",
        borderRadius: "3px",
        verticalAlign: "middle",
        fontSize: "12px",
        color: "#ffffff",
        backgroundColor: "#00548E",
      }}
      className="label label-danger"
    >
      {i18n.t("common.required") as string}
    </span>
  );
};
export default RequiredLabel;
