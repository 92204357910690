import { CountryCode } from "config/BaseConfig";
import classes from "./SubHeader.module.scss";

type Props = {
  text: string;
};

const SubHeader = ({ text }: Props) => {
  return (
    <div
      className={
        CountryCode === "US" ? classes.main_css_us : classes.main_css_jp
      }
    >
      {text}
    </div>
  );
};

export default SubHeader;
