import CheckIcon from "@rsuite/icons/Check";
import React from "react";

interface Props {
  title: string;
  text: string;
  isActive: boolean;
  isDone?: boolean;
}

const Step: React.FC<Props> = ({ title, text, isActive, isDone = false }) => {
  return (
    <li className={`step ${isActive ? "active " : ""}${isDone ? "done" : ""}`}>
      <div className={"title"}>{isDone ? <CheckIcon /> : title}</div>
      <div
        className={"description"}
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        {text}
      </div>
    </li>
  );
};
export default Step;
