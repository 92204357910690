import Button from "components/atoms/Button";
import FormLabel from "components/atoms/FormLabel";
import Input from "components/atoms/Input";
import InputPicker from "components/atoms/InputPicker";
import FormHeaderGroup from "components/molecules/FormHeaderGroup";
import { DeployPage } from "config/BaseConfig";
import { useApplicationSettings } from "hooks/useApplicationSettings";
import usePageTracking from "hooks/useTracking";
import i18n from "i18next";
import React, { useCallback, useEffect, useState } from "react";
import ReactGA from "react-ga4";
import ReCAPTCHA from "react-google-recaptcha";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { FlexboxGrid, Form, Message } from "rsuite";
import { ValueType } from "rsuite/esm/Checkbox";
import ConsoleAPIClient from "service/utils/ConsoleAPIClient";
import { applicationSettingsState } from "stores/applicationSettings";
import { accountIdState, leadIdState } from "stores/customerSettings";
import classes from "./RegisterPage.module.scss";
import { Helmet } from "react-helmet";
import MiitelMeetingsRegister from "../MiitelMeetingsRegister";
import { isValidEmail } from "service/utils/ValidationUtil";
import DemoRequestRegister from "../DemoRequest";
type valueType = {
  company_name: string;
  first_name: string;
  last_name: string;
  title: string;
  phone_number: string;
  email: string;
  number_of_employees: string;
  meeting_tools: string[];
  accepted: string;
  ceo_name?: string;
  headquarter?: string;
  is_miitel_user?: string;
};

interface RegisterProps { }

const Register: React.FC<RegisterProps> = () => {
  useEffect(() => {
    if (
      DeployPage === "APPLICATION_FORM" ||
      (DeployPage === undefined && getLastPath() === "meetings")
    ) {
      document.title =
        "トライアルプラン申し込み | MiiTel Meetings（ミーテルミーティング）";
    } else if (
      DeployPage === "DOCUMENT_REQUEST_CC" ||
      getLastPath() === "document-request-cc"
    ) {
      document.title = "資料請求フォーム | MiiTel Call Center";
    } else if (
      DeployPage === "DOCUMENT_REQUEST_RECPOD" ||
      getLastPath() === "document-request-recpod"
    ) {
      document.title = "資料請求 | MiiTel RecPod（ミーテルレックポッド）";
    } else if (
      DeployPage === "DEMO_REQUEST_CC" ||
      getLastPath() === "demo-request-cc"
    ) {
      document.title =
        "デモ希望フォーム | MiiTel（ミーテル）";
    } else if (
      DeployPage === "DEMO_REQUEST" ||
      getLastPath() === "demo-request"
    ) {
      document.title =
        "デモ希望フォーム | MiiTel（ミーテル）";
    } else {
      document.title = "資料請求 | MiiTel Meetings（ミーテルミーティング）";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [utmSource, setUtmSource] = useState<string>("");
  const [utmMedium, setUtmMedium] = useState<string>("");
  const [utmCampaign, setUtmCampaign] = useState<string>("");
  const [utmContent, setUtmContent] = useState<string>("");
  const location = useLocation();
  const search = useLocation().search;
  const navigate = useNavigate();

  useEffect(() => {
    const query = new URLSearchParams(search);
    const queryUtmSource = query.get("utm_source");
    if (queryUtmSource) {
      setUtmSource(queryUtmSource);
    }
    const queryUtmMedium = query.get("utm_medium");
    if (queryUtmMedium) {
      setUtmMedium(queryUtmMedium);
    }
    const queryUtmCampaign = query.get("utm_campaign");
    if (queryUtmCampaign) {
      setUtmCampaign(queryUtmCampaign);
    }
    const queryUtmContent = query.get("utm_content");
    if (queryUtmContent) {
      setUtmContent(queryUtmContent);
    }
  }, [search, setUtmSource, setUtmMedium, setUtmCampaign, setUtmContent]);

  const TitleSelections = [
    { label: "役員・経営者", value: "役員・経営者" },
    { label: "部長・事業部長", value: "部長・事業部長" },
    { label: "課長・エリアマネージャー", value: "課長・エリアマネージャー" },
    { label: "主任・リーダー", value: "主任・リーダー" },
    { label: "一般", value: "一般" },
    { label: "その他", value: "その他" },
  ];

  usePageTracking();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [values, setValues] = useState<valueType>({
    company_name: "",
    first_name: "",
    last_name: "",
    title: "",
    phone_number: "",
    email: "",
    number_of_employees: "",
    meeting_tools: [],
    ceo_name: "",
    headquarter: "",
    accepted: "0",
  });
  const setLeadId = useSetRecoilState(leadIdState);
  const setAccountId = useSetRecoilState(accountIdState);
  const [progressMessage, setProgressMessage] = useState<string>("");
  const [errors, setErrors] = useState<string[]>([]);

  const applicationSettings = useRecoilValue(applicationSettingsState);
  const { setApplicationSettings } = useApplicationSettings();

  const getLastPath = useCallback(() => {
    let fullPath = location.pathname;
    if (fullPath.endsWith("/")) {
      fullPath = fullPath.slice(0, -1);
    }
    const pathArray = fullPath.split("/");
    return pathArray[pathArray.length - 1];
  }, [location.pathname]);

  useEffect(() => {
    const query = new URLSearchParams(search);
    const token = query.get("token");
    if (token) {
      navigate("/payment-setup?token=" + token);
    }
    (async () => {
      await ConsoleAPIClient.getWithoutAuth("/api/health");
    })();
    setApplicationSettings({
      contractType: "yearly_contract",
      full_user: 0,
      view_only_user: 0,
      zoom_user: 0,
      tenant_code: "",
      customer_id: "",
      email: "",
    });
    setAccountId("");
  }, [setApplicationSettings, navigate, setAccountId, search]);

  const handleSubmit = useCallback(async () => {
    ReactGA.initialize("G-DSDMM4BM02");
    ReactGA.event({
      category: "Form",
      action: "Submit",
      label: "Application",
    });
    setIsSubmitting(true);
    setErrors([]);
    if (
      !values.company_name ||
      !values.first_name ||
      !values.last_name ||
      !values.phone_number ||
      !values.email ||
      (!values.title && (!values.number_of_employees || !values.meeting_tools))
    ) {
      setErrors((errors) => [...errors, "必須項目が入力されていません。"]);
      window.scrollTo({ top: 0, behavior: "smooth" });
      setIsSubmitting(false);
      return;
    }
    if (!isValidEmail(values.email)) {
      setErrors((errors) => [
        ...errors,
        "メールアドレスの形式が正しくありません。",
      ]);
      window.scrollTo({ top: 0, behavior: "smooth" });
      setIsSubmitting(false);
      return;
    }
    let is_document_request_cc = false;
    if (
      DeployPage === "DOCUMENT_REQUEST_CC" ||
      getLastPath() === "document-request-cc"
    ) {
      is_document_request_cc = true;
    }
    let is_document_request_recpod = false;
    if (
      DeployPage === "DOCUMENT_REQUEST_RECPOD" ||
      getLastPath() === "document-request-recpod"
    ) {
      is_document_request_recpod = true;
    }
    // 登録処理
    try {
      setProgressMessage("お客様情報を登録しています。");
      const newMeetingTools: ValueType[] = values.meeting_tools.map((item) => {
        if (item === "Zoom") {
          return "ZOOM";
        } else if (item === "Microsoft Teams") {
          return "teams";
        }
        return item;
      });
      // バックエンドへのリクエスト
      const response = await ConsoleAPIClient.postWithoutAuth(
        "/api/jp/v1/customers",
        {
          customer: {
            company_name: values.company_name,
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email,
            title: values.title,
            phone_number: values.phone_number,
            how_many_users: applicationSettings.zoom_user,
            number_of_employees: values.number_of_employees,
            meeting_tools: newMeetingTools.join(";"),
            utm_source: utmSource,
            utm_medium: utmMedium,
            utm_campaign: utmCampaign,
            utm_content: utmContent,
            is_document_request_cc: is_document_request_cc,
            is_document_request_recpod: is_document_request_recpod,
            is_online_application: false,
            ceo_name: values.ceo_name,
            headquarter: values.headquarter,
            tenant_code: applicationSettings.tenant_code,
            zoom_user: applicationSettings.zoom_user,
            is_miitel_user: values.is_miitel_user,
          },
        }
      );
      setLeadId(response.data.customer.id);
      // リダイレクト処理
      setProgressMessage("");
      navigate("/finished?pageType=document-request");
    } catch {
      window.scrollTo({ top: 0, behavior: "smooth" });
      setErrors((errors) => [
        ...errors,
        "エラーが発生しました。お手数ですが、お問い合わせください。",
      ]);
      setProgressMessage("");
      setIsSubmitting(false);
    }
  }, [
    values.company_name,
    values.first_name,
    values.last_name,
    values.phone_number,
    values.email,
    values.title,
    values.number_of_employees,
    values.meeting_tools,
    values.ceo_name,
    values.headquarter,
    values.is_miitel_user,
    getLastPath,
    applicationSettings.tenant_code,
    applicationSettings.zoom_user,
    utmSource,
    utmMedium,
    utmCampaign,
    utmContent,
    setLeadId,
    navigate,
  ]);

  const handleValueInputChange = (
    value: string | number | readonly string[],
    event: React.SyntheticEvent<Element, Event>
  ) => {
    const name: string | null = event.currentTarget.getAttribute("name");
    if (name == null) {
      return;
    }
    var str_value: string = value.toString();
    if (name === "accepted") {
      if (values.accepted === "0") {
        str_value = "1";
      } else {
        str_value = "0";
      }
    }
    setValues({ ...values, [name]: str_value });
  };
  const setValuesEvent = (
    value: string | number | readonly string[] | ValueType[],
    name: string
  ) => {
    const obj = {
      ...values,
      [name]: value,
    };
    setValues(obj);
  };
  const handleTitleSelectInputChange = (
    value: string | number | readonly string[],
    _: React.SyntheticEvent<Element, Event>
  ) => {
    setValuesEvent(value, "title");
  };

  return (
    <div>
      {DeployPage === "APPLICATION_FORM" ||
        DeployPage === "DOCUMENT_REQUEST_MM" ||
        (DeployPage === undefined && getLastPath() === "meetings") ? (
        <Helmet>
          <meta
            name="title"
            content="トライアルプラン申し込み | MiiTel Meetings（ミーテルミーティング）"
          />
          <meta
            name="description"
            content={
              "MiiTel Meetingsのトライアル申し込みページです。AIによる文字起こしと議事録作成、トーク分析機能など、実際の使い心地をお試しいただけます。"
            }
          />
        </Helmet>
      ) : DeployPage === "DOCUMENT_REQUEST_CC" ? (
        <Helmet>
          <meta name="title" content="資料請求フォーム | MiiTel Call Center" />
          <meta
            name="description"
            content={
              "MiiTel Call Centerは、AIによる文字起こしとトーク分析機能により、オペレーター状況の可視化を行い、対応品質の向上だけでなく、スーパーバイザーの業務負担の軽減を行います。"
            }
          />
        </Helmet>
      ) : DeployPage === "DOCUMENT_REQUEST_RECPOD" ? (
        <Helmet>
          <meta
            name="title"
            content="資料請求 | MiiTel RecPod（ミーテルレックポッド）"
          />
          <meta
            name="description"
            content={
              "MiiTel RecPodは、AIによる文字起こしと議事録作成、トーク分析機能により、セールスや窓口業務の効率化はもちろん、会議を行うすべての人の生産性向上を支えます。"
            }
          />
        </Helmet>
      ) : DeployPage === "DEMO_REQUEST_CC" ? (
        <Helmet>
          <meta
            name="title"
            content="デモ希望 | MiiTel Call Center（ミーテルコールセンター）"
          />
          <meta
            name="description"
            content={
              "MiiTel Call Centerは、AIによる文字起こしとトーク分析機能により、オペレーター状況の可視化を行い、対応品質の向上だけでなく、スーパーバイザーの業務負担の軽減を行います。"
            }
          />
        </Helmet>
      ) : null}
      {DeployPage === "APPLICATION_FORM" ||
        (DeployPage === undefined && getLastPath() === "meetings") ? (
        <MiitelMeetingsRegister
          utmCampaign={utmCampaign}
          utmContent={utmContent}
          utmMedium={utmMedium}
          utmSource={utmSource}
        />
      ) : DeployPage === "DEMO_REQUEST_CC" ||
        getLastPath() === "demo-request-cc" ||
        DeployPage === "DEMO_REQUEST" ||
        getLastPath() === "demo-request" ? (
        <DemoRequestRegister
          utmCampaign={utmCampaign}
          utmContent={utmContent}
          utmMedium={utmMedium}
          utmSource={utmSource}
        />
      ) : (
        <>
          <div
            style={{
              width: "100%",
              height: "auto",
              display: "none",
            }}
          ></div>
          <FlexboxGrid justify="center" style={{ paddingBlock: "60px" }}>
            <FlexboxGrid
              justify="center"
              style={{
                width: "100%",
              }}
            >
              <FormHeaderGroup sub_header={""} header="資料請求フォーム" />
              <FlexboxGrid.Item
                style={{
                  paddingTop: "25px",
                  display: "flex",
                  justifyContent: "center",
                }}
                colspan={24}
              >
                <Form className={classes["form-content-flexgrid"]}>
                  {errors.length > 0 ? (
                    <Message
                      className={classes["message-alert"]}
                      showIcon
                      type="error"
                      header="Error"
                    >
                      {errors.map((error, idx) => {
                        return <p key={idx}>{error}</p>;
                      })}
                    </Message>
                  ) : null}
                  <Form.Group
                    controlId="company_name"
                    style={{ paddingBlock: "20px", marginBottom: "0px" }}
                  >
                    <FormLabel
                      text={i18n.t("register_form.company_name") as string}
                      required={true}
                    />
                    <Input
                      name="company_name"
                      value={values.company_name}
                      onChange={handleValueInputChange}
                      type="text"
                      disabled={false}
                      step=""
                      placeHolder={
                        i18n.t("register_form.samples.company_name") as string
                      }
                    />
                  </Form.Group>
                  <FlexboxGrid
                    justify="space-between"
                    style={{ paddingBlock: "20px", marginBottom: "0px" }}
                  >
                    <FlexboxGrid.Item
                      colspan={12}
                      className={classes["input-name-flexgrid-left"]}
                    >
                      <Form.Group controlId="name">
                        <FormLabel
                          text={i18n.t("register_form.last_name") as string}
                          required={true}
                        />
                        <Input
                          name="last_name"
                          value={values.last_name}
                          onChange={handleValueInputChange}
                          type="text"
                          disabled={false}
                          step=""
                          placeHolder={
                            i18n.t("register_form.samples.last_name") as string
                          }
                        />
                      </Form.Group>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item
                      colspan={12}
                      className={classes["input-name-flexgrid-right"]}
                    >
                      <Form.Group controlId="name">
                        <FormLabel
                          text={i18n.t("register_form.first_name") as string}
                        />
                        <Input
                          name="first_name"
                          value={values.first_name}
                          onChange={handleValueInputChange}
                          type="text"
                          disabled={false}
                          step=""
                          placeHolder={
                            i18n.t("register_form.samples.first_name") as string
                          }
                        />
                      </Form.Group>
                    </FlexboxGrid.Item>
                  </FlexboxGrid>

                  <Form.Group
                    controlId="title"
                    style={{ paddingBlock: "20px", marginBottom: "0px" }}
                  >
                    <FormLabel
                      text={i18n.t("register_form.title") as string}
                      required={true}
                    />
                    <InputPicker
                      name="title"
                      data={TitleSelections}
                      value={values.title}
                      onChange={handleTitleSelectInputChange}
                      disabled={false}
                    />
                  </Form.Group>
                  <Form.Group
                    controlId="phone_number"
                    style={{ paddingBlock: "20px", marginBottom: "0px" }}
                  >
                    <FormLabel
                      text={i18n.t("register_form.phone_number") as string}
                      required={true}
                    />
                    <Input
                      name="phone_number"
                      value={values.phone_number}
                      onChange={handleValueInputChange}
                      type="phone_number"
                      disabled={false}
                      step=""
                      placeHolder={
                        i18n.t("register_form.samples.phone_number") as string
                      }
                    />
                  </Form.Group>
                  <Form.Group
                    controlId="email"
                    style={{ paddingBlock: "20px", marginBottom: "0px" }}
                  >
                    <FormLabel
                      text={i18n.t("register_form.email") as string}
                      required={true}
                    />
                    <Input
                      name="email"
                      value={values.email}
                      onChange={handleValueInputChange}
                      type="email"
                      disabled={false}
                      step=""
                      placeHolder={
                        i18n.t("register_form.samples.email") as string
                      }
                    />
                  </Form.Group>
                  <Form.Group
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    <ReCAPTCHA
                      sitekey="6LdfqOQZAAAAAFe8i_GhcQWu39jG2fcp85xOcKUu"
                      onChange={(e: any) => console.log(e)}
                    />
                  </Form.Group>

                  {isSubmitting && progressMessage !== "" ? (
                    <Form.Group>
                      <Message
                        className={classes["message-alert"]}
                        showIcon
                        type="info"
                        header="しばらくお待ち下さい。"
                      >
                        <p>{progressMessage}</p>
                      </Message>
                    </Form.Group>
                  ) : null}

                  <Form.Group
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    <Button
                      text={"下記に同意し入力内容を送信"}
                      onClick={handleSubmit}
                      isLoading={isSubmitting}
                    />
                  </Form.Group>

                  <Form.Group
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      display: "none",
                    }}
                  >
                    <p>
                      申込月の翌月末日まで無償でご利用いただけます。 <br />
                      無償期間終了の3週間前にメールでご案内差し上げます。
                    </p>
                  </Form.Group>
                </Form>
              </FlexboxGrid.Item>
            </FlexboxGrid>
          </FlexboxGrid>
        </>
      )}
    </div>
  );
};

export default Register;
