import { CountryCode } from "config/BaseConfig";
import React from "react";
import MiiTelLogoJP from "../../../assets/img/logo mark.svg";
import classes from "./SecondaryHeader.module.scss";

interface Props {}
const Header: React.FC<Props> = () => {
  return (
    <header className={classes["main_css_" + CountryCode]}>
      <div className={classes["header_logo-inner-container"]}>
        <div className={classes["header_logo-inner-container-nav"]}>
          <div className={classes["header_logo-inner-container-nav-item"]}>
            <a
              className={classes["logo_css_" + CountryCode]}
              href={"https://miitel.com/jp/meetings"}
            >
              <img
                src={MiiTelLogoJP}
                alt=""
                data-pagespeed-url-hash="2434161800"
              />
            </a>
          </div>
          <div
            className={classes["header_logo-inner-container-nav-item-space"]}
          ></div>
        </div>
      </div>
      <div className={classes["rectangle"]}></div>
    </header>
  );
};

export default Header;
