import i18n from "i18next";
import React, { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { FlexboxGrid, Form, Panel } from "rsuite";
import Button from "../../../components/atoms/Button";
import FormLabel from "../../../components/atoms/FormLabel";
import Input from "../../../components/atoms/Input";
import InputNumberGroup from "../../../components/molecules/InputNumberGroup";
import Steps from "../../../components/molecules/Steps";
import { applicationSettingsState } from "../../../stores/applicationSettings";

const validateTenantCode = (tenant_code: string) => {
  const ok_pattern = new RegExp("[0-9a-zA-Z\\-\\_]+");
  return (
    ok_pattern.test(tenant_code) &&
    tenant_code.length >= 5 &&
    tenant_code.indexOf(" ") < 0
  );
};

interface Props {}

const SelectFunctions: React.FC<Props> = () => {
  const [applicationSettings, setApplicationSettings] = useRecoilState(
    applicationSettingsState
  );
  const navigate = useNavigate();
  const search = useLocation().search;
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const query = new URLSearchParams(search);

  const setApplicationSettingsEvent = (
    value: string | number | readonly string[],
    name: string
  ) => {
    const obj = {
      ...applicationSettings,
      [name]: value,
    };
    setApplicationSettings(obj);
  };

  const handleInputChange = (
    value: string | number | readonly string[],
    event: React.SyntheticEvent<Element, Event>
  ) => {
    const name: string | null = event.currentTarget.getAttribute("name");
    if (name == null) {
      return;
    }
    setApplicationSettingsEvent(value, name);
  };

  const handleMinus = (target: string) => {
    if (
      target === "full_user" ||
      target === "view_only_user" ||
      target === "zoom_user"
    ) {
      const nextValue = Number(applicationSettings[target]) - 1;
      if (nextValue < 1) {
        return;
      }
      setApplicationSettingsEvent(nextValue, target);
    }
  };

  const handlePlus = (target: string) => {
    if (
      target === "full_user" ||
      target === "view_only_user" ||
      target === "zoom_user"
    ) {
      const nextValue = Number(applicationSettings[target]) + 1;
      setApplicationSettingsEvent(nextValue, target);
    }
  };

  const handleSubmit = useCallback(async () => {
    if (!validateTenantCode(applicationSettings.tenant_code)) {
      setIsSubmitting(false);
      return;
    }
    navigate("/card-setup");
  }, [navigate, applicationSettings]);

  return (
    <>
      {" "}
      <Steps activeStep={3} />
      <FlexboxGrid justify="center">
        <FlexboxGrid.Item
          colspan={24}
          style={{
            padding: "30px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Panel shaded header={i18n.t("functions.title") as string}>
            <Form
              style={{
                maxWidth: "500px",
              }}
            >
              <Form.Group controlId="tenant_code">
                <FormLabel text={i18n.t("functions.tenant_code") as string} />
                <Input
                  name="tenant_code"
                  value={applicationSettings.tenant_code}
                  onChange={handleInputChange}
                  type="tenant_code"
                  disabled={false}
                  step=""
                  placeHolder={
                    i18n.t("functions.samples.tenant_code") as string
                  }
                />
                <Form.HelpText>
                  {i18n.t("functions.tenant_code_description1") as string}
                  <br></br>
                  {i18n.t("functions.tenant_code_description2") as string}
                </Form.HelpText>
              </Form.Group>
              <Form.Group
                controlId="full_user"
                hidden={query.get("service") !== "miitel"}
              >
                <FormLabel text={i18n.t("functions.full_user") as string} />
                <InputNumberGroup
                  name="full_user"
                  value={applicationSettings.full_user}
                  onChange={handleInputChange}
                  disabled={false}
                  handleMinus={handleMinus}
                  handlePlus={handlePlus}
                />
              </Form.Group>
              <Form.Group
                controlId="view_only_user"
                hidden={query.get("service") !== "miitel"}
              >
                <FormLabel
                  text={i18n.t("functions.view_only_user") as string}
                />
                <InputNumberGroup
                  name="view_only_user"
                  value={applicationSettings.view_only_user}
                  onChange={handleInputChange}
                  disabled={false}
                  handleMinus={handleMinus}
                  handlePlus={handlePlus}
                />
              </Form.Group>
              <Form.Group
                controlId="zoom_user"
                hidden={query.get("service") !== "miitel-for-zoom"}
              >
                <FormLabel
                  text={i18n.t("functions.zoom_user_amount") as string}
                />
                <InputNumberGroup
                  name="zoom_user"
                  value={applicationSettings.zoom_user}
                  onChange={handleInputChange}
                  disabled={false}
                  handleMinus={handleMinus}
                  handlePlus={handlePlus}
                />
              </Form.Group>

              <Form.Group
                style={{
                  alignItems: "center",
                  justifyContent: "flex-end",
                  display: "flex",
                }}
              >
                <Button
                  text={i18n.t("common.next") as string}
                  onClick={handleSubmit}
                  isLoading={isSubmitting}
                />
              </Form.Group>
            </Form>
          </Panel>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </>
  );
};

export default SelectFunctions;
