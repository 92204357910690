import { FormValueType } from "pages/jp/ContinuousPage/type";

export const initialFormValues: FormValueType = {
  email: "",
  company_name: "",
  customer_name: "",
  tenant_code: "",
  phone_number: "",
  request_type: "continue",
  contract_type: "yearly",
  how_many_users: 1,
  cancel_reasons: [],
  additional_message: "",
  accepted: "0",
};

export const requestTypes = [
  { label: "有償契約の切替を希望する (見積依頼)", value: "continue" },
  { label: "無償トライアル後解約する", value: "cancel" },
];

export const contractTypes = [
  { label: "年次契約", value: "yearly" },
  { label: "月次契約", value: "monthly" },
];

export const cancelReasons = [
  "①文字起こし、音声認識の精度が期待に沿わなかった",
  "②分析機能への不満（行いたい分析ができない等）",
  "③外部連携ができない（希望のツールと連携できない）",
  "④サービスの操作感に不満",
  "⑤費用対効果（利用料金が高い等）",
  "⑥運用が定着せず（組織文化が醸成できず等）",
  "その他",
];
