import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import i18n from "i18next";
import React from "react";
import { FlexboxGrid, Panel } from "rsuite";
import Steps from "../../../components/molecules/Steps";

interface Props {}

const Finished: React.FC<Props> = () => {
  return (
    <>
      <Steps activeStep={5} />
      <FlexboxGrid justify="center">
        <FlexboxGrid.Item
          colspan={24}
          style={{
            paddingTop: "25px",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <Panel shaded>
            <div
              style={{
                maxWidth: "500px",
              }}
            >
              <FlexboxGrid
                justify="center"
                style={{ color: "#6BA0FC", paddingTop: "53px" }}
              >
                <FontAwesomeIcon icon={faCheckCircle} size="6x" />
              </FlexboxGrid>
              <FlexboxGrid justify="center">
                <p className="thank-you-title">Thank you!</p>
              </FlexboxGrid>
              <FlexboxGrid justify="center">
                <p className="thank-you-message">
                  {i18n.t("finished.thanks") as string}
                  <br />
                  {i18n.t("finished.detail") as string}
                </p>
              </FlexboxGrid>
            </div>
          </Panel>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </>
  );
};

export default Finished;
