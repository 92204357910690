import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactGA from "react-ga4";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import i18n from "i18next";
import { Helmet } from "react-helmet";
import { ValueType } from "rsuite/esm/Checkbox";
import { CheckboxGroup, FlexboxGrid, Form, Message } from "rsuite";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ja from "date-fns/locale/ja";

import classes from "./DemoRequest.module.scss";
import Button from "components/atoms/Button";
import FormLabel from "components/atoms/FormLabel";
import Input from "components/atoms/Input";
import InputPicker from "components/atoms/InputPicker";
import InputCheckbox from "components/atoms/InputCheckbox/InputCheckbox";
import FormHeaderGroup from "components/molecules/FormHeaderGroup";

import usePageTracking from "hooks/useTracking";
import { useErrorMessages } from "hooks/useErrorMessage";
import ConsoleAPIClient from "service/utils/ConsoleAPIClient";
import {
  isValidEmail,
  isValidResponseTenantCode,
  isValidTenantCode,
} from "service/utils/ValidationUtil";
import {
  IsTenantCodeExistRequest,
  IsTenantCodeExistResponse,
} from "../MiitelMeetingsRegister/type";
import {
  DatetimeTypeKeys,
  DemoRequestBody,
  DemoRequestResponse,
  FormValueType,
  FormValueTypeKeys,
} from "./type";
import { roundToNearest15Minutes } from "service/utils/DateUtil";

interface DemoRequestRegisterProps {
  utmSource: string;
  utmMedium: string;
  utmCampaign: string;
  utmContent: string;
}

const DemoRequestRegister: React.FC<DemoRequestRegisterProps> = (
  props: DemoRequestRegisterProps
) => {
  const { utmSource, utmMedium, utmCampaign, utmContent } = props;
  const navigate = useNavigate();
  const errorRef = useRef<HTMLDivElement>(null);

  const TitleSelections = [
    { label: "役員・経営者", value: "役員・経営者" },
    { label: "部長・事業部長", value: "部長・事業部長" },
    { label: "課長・エリアマネージャー", value: "課長・エリアマネージャー" },
    { label: "主任・リーダー", value: "主任・リーダー" },
    { label: "一般", value: "一般" },
    { label: "その他", value: "その他" },
  ];
  const ServiceForDemo = ["MiiTel Call Center ( コールセンター機能 )", "MiiTel Scan To Call ( コールトラッキング機能 )"];

  usePageTracking();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [values, setValues] = useState<FormValueType>({
    service_for_demo: [],
    company_name: "",
    tenant_code: "",
    first_name: "",
    last_name: "",
    title: "",
    phone_number: "",
    email: "",
    first_choice: "",
    second_choice: "",
    third_choice: "",
  });
  const { errors, showErrorMessage, clearErrors } = useErrorMessages();
  const [progressMessage, setProgressMessage] = useState<string>("");

  useEffect(() => {
    if (errors.length > 0 && errorRef.current) {
      errorRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [errors]);

  const isBlankRequiredFields = useCallback(
    (values: FormValueType): boolean => {
      return (
        values.service_for_demo.length === 0 ||
        !values.company_name ||
        !values.first_name ||
        !values.last_name ||
        !values.email ||
        !values.phone_number ||
        (!values.first_choice && !values.second_choice && !values.third_choice)
      );
    },
    []
  );

  const isValidDateTime = (values: FormValueType) => {
    // 3つの希望日時のうち、1つでも入力されているかどうかを確認
    const choices = Object.keys(values)
      .map((key) => {
        if (
          (key as FormValueTypeKeys).includes("choice") &&
          values[key as FormValueTypeKeys]
        ) {
          return new Date(values[key as DatetimeTypeKeys]) as Date;
        }
        return null;
      })
      .filter((choice) => choice !== null) as Date[];

    if (choices.length === 0) {
      return false;
    }

    // 過去の日時、10時から17時以外、15分単位でない場合はエラー
    const isValid = choices.every((choice) => {
      return (
        choice >= new Date() &&
        choice.getHours() >= 10 &&
        choice.getHours() <= 17 &&
        choice.getMinutes() % 15 === 0
      );
    });
    return isValid;
  };

  const handleSubmit = useCallback(async () => {
    console.info("Submit information to the server");
    setIsSubmitting(true);
    ReactGA.initialize("G-DSDMM4BM02");
    ReactGA.event({
      category: "Form",
      action: "Submit",
      label: "Application",
    });
    clearErrors();
    let errorMessages: string[] = [];
    if (isBlankRequiredFields(values)) {
      errorMessages.push(
        i18n.t("demo_request_form.error.messages.required") as string
      );
    }
    if (values.tenant_code && !isValidTenantCode(values.tenant_code)) {
      errorMessages.push(
        i18n.t("demo_request_form.error.messages.tenant_code") as string
      );
    }
    if (!isValidEmail(values.email)) {
      errorMessages.push(
        i18n.t("demo_request_form.error.messages.email") as string
      );
    }
    if (!isValidDateTime(values)) {
      errorMessages.push(
        i18n.t("demo_request_form.error.messages.date_time") as string
      );
    }
    if (errorMessages.length > 0) {
      showErrorMessage(errorMessages);
      setIsSubmitting(false);
      return;
    }

    try {

      // Register demo request
      setProgressMessage(
        i18n.t("demo_request_form.progress_messages.registering") as string
      );
      const demoRequestBody: DemoRequestBody = {
        demo: {
          service_for_demo: values.service_for_demo.map(s => s.split(" (")[0]).join(", "),
          company_name: values.company_name,
          tenant_code: values.tenant_code,
          first_name: values.first_name,
          last_name: values.last_name,
          title: values.title,
          phone_number: values.phone_number,
          email: values.email,
          first_choice: values.first_choice,
          second_choice: values.second_choice,
          third_choice: values.third_choice,
          utm_source: utmSource,
          utm_medium: utmMedium,
          utm_campaign: utmCampaign,
          utm_content: utmContent,
        },
      };
      const demoRequestResponse = await ConsoleAPIClient.postWithoutAuth(
        "/api/jp/v1/demo_requests",
        demoRequestBody
      );

      const {
        demo: { is_success, message },
      } = demoRequestResponse.data as DemoRequestResponse;
      // 正常に登録されなかった場合はエラーメッセージを表示する
      if (!is_success) {
        console.info("Error occurred while registering demo request", message);
        showErrorMessage([message]);
        setIsSubmitting(false);
        return;
      }

      console.info("Demo request is registered successfully!");
      navigate("/finished?pageType=demo-request");
    } catch (error) {
      console.error("Error occurred while registering demo request", error);
      showErrorMessage();
    } finally {
      setProgressMessage("");
      setIsSubmitting(false);
    }
  }, [
    isBlankRequiredFields,
    clearErrors,
    showErrorMessage,
    values,
    setIsSubmitting,
    navigate,
    utmSource,
    utmMedium,
    utmCampaign,
    utmContent,
  ]);
  const setValuesEvent = (
    value: string | number | Date | readonly string[] | ValueType[],
    name: string
  ) => {
    if (value instanceof Date) {
      value = roundToNearest15Minutes(value).toLocaleString("ja-JP", {
        timeZone: "Asia/Tokyo",
        hour12: false,
      });
    }
    const obj = {
      ...values,
      [name]: value,
    };
    setValues(obj);
  };

  registerLocale("ja", ja);
  const handleDateChange = (date: Date, name: string) => {
    setValuesEvent(date, name);
  };

  const isWithinTimeRange = (time: Date) => {
    const selectedTime = new Date(time);
    const startTime = new Date(selectedTime);
    startTime.setHours(10, 0, 0, 0);
    const endTime = new Date(selectedTime);
    endTime.setHours(18, 0, 0, 0);

    return selectedTime >= startTime && selectedTime <= endTime;
  };

  const handleValueInputChange = (
    value: string | number | readonly string[],
    event: React.SyntheticEvent<Element, Event>
  ) => {
    const name = event.currentTarget.getAttribute("name");
    if (!name) return;
    setValues({ ...values, [name]: value });
  };

  const handleCheckBoxChange = (value: ValueType[]) => {
    setValuesEvent(value, "service_for_demo");
  }

  return (
    <div>
      <Helmet>
        <meta
          name="title"
          content="デモ希望 | MiiTel Call Center（ミーテルコールセンター）"
        />
        <meta
          name="description"
          content={
            "MiiTel Call Centerは、AIによる文字起こしとトーク分析機能により、オペレーター状況の可視化を行い、対応品質の向上だけでなく、スーパーバイザーの業務負担の軽減を行います。"
          }
        />
      </Helmet>
      <>
        <div
          style={{
            width: "100%",
            height: "auto",
            display: "none",
          }}
        ></div>
        <FlexboxGrid justify="center" style={{ paddingBlock: "60px" }}>
          <FlexboxGrid
            justify="center"
            style={{
              width: "100%",
            }}
          >
            <FormHeaderGroup sub_header={""} header="デモ希望フォーム" />
            <FlexboxGrid.Item
              style={{
                paddingTop: "25px",
                display: "flex",
                justifyContent: "center",
              }}
              colspan={24}
            >
              <Form className={classes["form-content-flexgrid"]}>
                {errors.length > 0 ? (
                  <Message
                    className={classes["message-alert"]}
                    showIcon
                    type="error"
                    header="Error"
                    ref={errorRef}
                  >
                    {errors.map((error, idx) => {
                      return <p key={idx}>{error}</p>;
                    })}
                  </Message>
                ) : null}
                <Form.Group
                  controlId="service_for_demo"
                  style={{ paddingBlock: "20px", marginBottom: "0px" }}>
                  <FormLabel
                    text={i18n.t("demo_request_form.service_for_demo") as string}
                    required={true}
                  />
                  <CheckboxGroup
                    inline
                    name="service_for_demo"
                    value={values.service_for_demo}
                    onChange={handleCheckBoxChange}
                    style={{ backgroundColor: "#f7f7fa", marginLeft: "0px" }}
                    className="rs-flex-box-grid rs-flex-box-grid-top rs-flex-box-grid-start"
                  >
                    {ServiceForDemo.map((service) => (
                      <FlexboxGrid.Item key={service} colspan={24}>
                        <InputCheckbox value={service} />
                      </FlexboxGrid.Item>
                    ))}
                  </CheckboxGroup>
                </Form.Group>
                <Form.Group
                  controlId="company_name"
                  style={{ paddingBlock: "20px", marginBottom: "0px" }}
                >
                  <FormLabel
                    text={i18n.t("demo_request_form.company_name") as string}
                    required={true}
                  />
                  <Input
                    name="company_name"
                    value={values.company_name}
                    onChange={handleValueInputChange}
                    type="text"
                    disabled={false}
                    step=""
                    placeHolder={
                      i18n.t("demo_request_form.samples.company_name") as string
                    }
                  />
                </Form.Group>
                <Form.Group
                  controlId="tenant_code"
                  style={{ paddingBlock: "20px", marginBottom: "0px" }}
                >
                  <FormLabel
                    text={i18n.t("demo_request_form.tenant_code") as string}
                    required={false}
                  />
                  <Input
                    name="tenant_code"
                    value={values.tenant_code}
                    onChange={handleValueInputChange}
                    type="text"
                    disabled={false}
                    step=""
                    placeHolder={
                      i18n.t("demo_request_form.samples.tenant_code") as string
                    }
                  />
                </Form.Group>
                <Form.Group
                  controlId="title"
                  style={{ paddingBlock: "20px", marginBottom: "0px" }}
                >
                  <FormLabel
                    text={i18n.t("demo_request_form.title") as string}
                    required={false}
                  />
                  <InputPicker
                    name="title"
                    data={TitleSelections}
                    value={values.title}
                    onChange={(value: any, _: React.SyntheticEvent) =>
                      setValuesEvent(value, "title")
                    }
                    disabled={false}
                  />
                </Form.Group>
                <FlexboxGrid
                  justify="space-between"
                  style={{ paddingBlock: "20px", marginBottom: "0px" }}
                >
                  <FlexboxGrid.Item
                    colspan={12}
                    className={classes["input-name-flexgrid-left"]}
                  >
                    <Form.Group controlId="name">
                      <FormLabel
                        text={i18n.t("demo_request_form.last_name") as string}
                        required={true}
                      />
                      <Input
                        name="last_name"
                        value={values.last_name}
                        onChange={handleValueInputChange}
                        type="text"
                        disabled={false}
                        step=""
                        placeHolder={
                          i18n.t(
                            "demo_request_form.samples.last_name"
                          ) as string
                        }
                      />
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item
                    colspan={12}
                    className={classes["input-name-flexgrid-right"]}
                  >
                    <Form.Group controlId="name">
                      <FormLabel
                        text={i18n.t("demo_request_form.first_name") as string}
                      />
                      <Input
                        name="first_name"
                        value={values.first_name}
                        onChange={handleValueInputChange}
                        type="text"
                        disabled={false}
                        step=""
                        placeHolder={
                          i18n.t(
                            "demo_request_form.samples.first_name"
                          ) as string
                        }
                      />
                    </Form.Group>
                  </FlexboxGrid.Item>
                </FlexboxGrid>
                <Form.Group
                  controlId="phone_number"
                  style={{ paddingBlock: "20px", marginBottom: "0px" }}
                >
                  <FormLabel
                    text={i18n.t("demo_request_form.phone_number") as string}
                    required={true}
                  />
                  <Input
                    name="phone_number"
                    value={values.phone_number}
                    onChange={handleValueInputChange}
                    type="phone_number"
                    disabled={false}
                    step=""
                    placeHolder={
                      i18n.t("demo_request_form.samples.phone_number") as string
                    }
                  />
                </Form.Group>
                <Form.Group
                  controlId="email"
                  style={{ paddingBlock: "20px", marginBottom: "0px" }}
                >
                  <FormLabel
                    text={i18n.t("demo_request_form.email") as string}
                    required={true}
                  />
                  <Input
                    name="email"
                    value={values.email}
                    onChange={handleValueInputChange}
                    type="email"
                    disabled={false}
                    step=""
                    placeHolder={
                      i18n.t("demo_request_form.samples.email") as string
                    }
                  />
                </Form.Group>
                <Form.Group>
                  <FormLabel
                    text={i18n.t("demo_request_form.request_date") as string}
                    required={true}
                  />
                  <p className={classes["attention-message"]}>
                    {i18n.t("demo_request_form.attention.text_1") as string}
                    <br />
                    {i18n.t("demo_request_form.attention.text_2") as string}
                  </p>
                  <FlexboxGrid style={{ gap: "20px", width: "100%" }}>
                    <FlexboxGrid.Item colspan={24}>
                      <p className={classes["date-picker-label"]}>
                        {i18n.t("demo_request_form.first_choice") as string}
                      </p>
                      <DatePicker
                        selected={
                          values.first_choice
                            ? new Date(values.first_choice)
                            : null
                        }
                        onChange={(date: Date) =>
                          handleDateChange(date, "first_choice")
                        }
                        dateFormat="yyyy-MM-dd HH:mm"
                        locale="ja"
                        className="Input_main_css_jp__UcWES rs-input"
                        showTimeSelect
                        wrapperClassName={classes["datePicker"]}
                        timeIntervals={15}
                        placeholderText={
                          i18n.t("demo_request_form.samples.datetime") as string
                        }
                        filterTime={isWithinTimeRange}
                      />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={24}>
                      <p className={classes["date-picker-label"]}>
                        {i18n.t("demo_request_form.second_choice") as string}
                      </p>
                      <DatePicker
                        selected={
                          values.second_choice
                            ? new Date(values.second_choice)
                            : null
                        }
                        onChange={(date: Date) =>
                          handleDateChange(date, "second_choice")
                        }
                        dateFormat="yyyy-MM-dd HH:mm"
                        locale="ja"
                        className="Input_main_css_jp__UcWES rs-input"
                        showTimeSelect
                        wrapperClassName={classes["datePicker"]}
                        timeIntervals={15}
                        placeholderText={
                          i18n.t("demo_request_form.samples.datetime") as string
                        }
                        filterTime={isWithinTimeRange}
                      />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={24}>
                      <p className={classes["date-picker-label"]}>
                        {i18n.t("demo_request_form.third_choice") as string}
                      </p>
                      <DatePicker
                        selected={
                          values.third_choice
                            ? new Date(values.third_choice)
                            : null
                        }
                        onChange={(date: Date) =>
                          handleDateChange(date, "third_choice")
                        }
                        dateFormat="yyyy-MM-dd HH:mm"
                        locale="ja"
                        className="Input_main_css_jp__UcWES rs-input"
                        showTimeSelect
                        wrapperClassName={classes["datePicker"]}
                        timeIntervals={15}
                        placeholderText={
                          i18n.t("demo_request_form.samples.datetime") as string
                        }
                        filterTime={isWithinTimeRange}
                      />
                    </FlexboxGrid.Item>
                  </FlexboxGrid>
                </Form.Group>
                <Form.Group
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <ReCAPTCHA
                    sitekey="6LdfqOQZAAAAAFe8i_GhcQWu39jG2fcp85xOcKUu"
                    onChange={(e: any) => console.log(e)}
                  />
                </Form.Group>

                {isSubmitting && progressMessage !== "" ? (
                  <Form.Group>
                    <Message
                      className={classes["message-alert"]}
                      showIcon
                      type="info"
                      header="しばらくお待ち下さい。"
                    >
                      <p>{progressMessage}</p>
                    </Message>
                  </Form.Group>
                ) : null}

                <Form.Group
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <Button
                    text={"デモを申し込む"}
                    onClick={handleSubmit}
                    isLoading={isSubmitting}
                  />
                </Form.Group>
              </Form>
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </FlexboxGrid>
      </>
    </div>
  );
};

export default DemoRequestRegister;
