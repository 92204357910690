import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import i18n from "i18next";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { Button, Col, FlexboxGrid, Form, Grid, Panel, Row } from "rsuite";
import Steps from "../../../components/molecules/Steps";
import StripeService from "../../../service/stripe/StripeService";
import ConsoleAPIClient from "../../../service/utils/ConsoleAPIClient";
import { applicationSettingsState } from "../../../stores/applicationSettings";

interface Props {}

const CardSetup: React.FC<Props> = () => {
  const myStyle = {
    style: {
      base: {
        color: "#32325d",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  const stripe = useStripe();
  const elements = useElements();
  const [customerId, setCustomerId] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const applicationSettings = useRecoilValue(applicationSettingsState);
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    const customer_id = localStorage.getItem("customer_id");
    const email = localStorage.getItem("email");
    if (
      customer_id !== "" &&
      customer_id !== null &&
      email !== null &&
      email !== ""
    ) {
      setCustomerId(customer_id);
      setEmail(email);
    } else {
      navigate("/");
    }
  }, [navigate]);

  const handleSubmit = async (event: React.SyntheticEvent<Element, Event>) => {
    event.preventDefault();
    setIsSubmitting(true);
    if (!stripe || !elements) {
      setIsSubmitting(false);
      return;
    }
    try {
      const response = await StripeService.confirmCardSetup(
        stripe,
        elements,
        customerId
      );
      const a = await ConsoleAPIClient.postWithoutAuth(
        "/api/v1/subscriptions",
        {
          subscription: {
            email: email,
            customer_id: customerId,
            default_payment_method_id: response.setupIntent.payment_method,
            tenant_code: applicationSettings.tenant_code,
            full_user: applicationSettings.full_user,
            view_only_user: applicationSettings.zoom_user,
            zoom_user: applicationSettings.zoom_user,
          },
        }
      );
      setIsSubmitting(false);
      navigate("/finished");
      return;
    } catch {
      setIsSubmitting(false);
      return;
    }
  };

  return (
    <>
      <Steps activeStep={4} />
      <FlexboxGrid justify="center">
        <FlexboxGrid.Item
          colspan={24}
          style={{
            paddingTop: "25px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Panel shaded header={i18n.t("card_setup.title") as string}>
            <Form
              style={{
                maxWidth: "500px",
              }}
            >
              <Form.Group>
                <Grid fluid style={{ paddingTop: "10px" }}>
                  <Row>
                    <Col xs={24}>
                      <label>
                        {i18n.t("card_setup.card_number") as string}
                      </label>
                      <CardNumberElement options={myStyle} />
                    </Col>
                  </Row>
                </Grid>
              </Form.Group>
              <Form.Group>
                <Grid fluid>
                  <Row>
                    <Col xs={12}>
                      <label>
                        {i18n.t("card_setup.card_month_year") as string}
                      </label>
                      <CardExpiryElement options={myStyle} />
                    </Col>
                    <Col xs={12}>
                      <label>{i18n.t("card_setup.card_cvc") as string}</label>
                      <CardCvcElement options={myStyle} />
                      <p style={{ paddingTop: "5px" }}>
                        {i18n.t("card_setup.cvc_help") as string}
                      </p>
                    </Col>
                  </Row>
                </Grid>
              </Form.Group>
              <Form.Group>
                <Grid fluid>
                  <Row>
                    <Col
                      xs={24}
                      style={{
                        alignItems: "center",
                        justifyContent: "flex-end",
                        display: "flex",
                      }}
                    >
                      <Button
                        style={{
                          color: "#FFFFFF",
                          height: "36px",
                          background: "#1764E9",
                          border: "1px solid #1764E9",
                          gap: "10px",
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                          borderRadius: "4px",
                          minWidth: "150px",
                        }}
                        loading={isSubmitting}
                        appearance="primary"
                        className="regist-card"
                        disabled={!stripe}
                        onClick={handleSubmit}
                      >
                        {i18n.t("common.next") as string}
                      </Button>
                    </Col>
                  </Row>
                </Grid>
              </Form.Group>
            </Form>
          </Panel>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </>
  );
};

export default CardSetup;
