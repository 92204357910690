import i18n from "i18next";
import React from "react";

import classes from "./SecondaryRequiredLabel.module.scss";

interface Props {}

const SecondaryRequiredLabel: React.FC<Props> = () => {
  return (
    <span className={classes["required-label"]}>
      <span className={classes["required-label-text"]}>
        {i18n.t("common.required") as string}
      </span>
    </span>
  );
};
export default SecondaryRequiredLabel;
