import CopyIcon from "@rsuite/icons/Copy";
import { Amplify, Auth, Hub } from "aws-amplify";
import i18n from "i18next";
import { useCallback, useEffect, useState } from "react";
import { FlexboxGrid, Form, IconButton, Message, Panel } from "rsuite";
import Button from "../../../components/atoms/Button";
import FormLabel from "../../../components/atoms/FormLabel";
import Input from "../../../components/atoms/Input";
import { AuthData, env } from "../../../config/BaseConfig";
import ConsoleAPIClient from "../../../service/utils/ConsoleAPIClient";
import classes from "./CallbackPage.module.scss";

Amplify.configure({
  Auth: AuthData,
});

export default function AccountCallback() {
  const [user, setUser] = useState<any | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [values, setValues] = useState({
    email: "",
    accountId: "",
  });
  const [token, setToken] = useState<string>("");
  const [accountName, setAccountName] = useState<string>("");
  const [accountCompany, setAccountCompany] = useState<string>("");
  const [accountEmail, setAccountEmail] = useState<string>("");
  const [error, setError] = useState<string>("");

  const copyToClipboard = async (text: string) => {
    await global.navigator.clipboard.writeText(text);
  };

  const createURL = () => {
    if (env !== "production") {
      return (
        window.location.protocol +
        "//" +
        window.location.host +
        "/jp/trial/meetings/payment-setup?token=" +
        token
      );
    } else {
      return "https://miitel.com/jp/trial/meetings/?token=" + token;
    }
  };

  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
        case "cognitoHostedUI":
          getUser().then((userData) => {
            setUser(userData);
          });
          break;
        case "signOut":
          setUser(null);
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          console.log("Sign in failure", data);
          break;
      }
    });

    getUser().then((userData) => {
      setUser(userData);
    });
  }, []);

  const CreateToken = async (email: string, account_id: string) => {
    // 検証用
    Auth.currentSession().then(async (data) => {
      localStorage.setItem("id_token", data.getIdToken().getJwtToken());
      localStorage.setItem("access_token", data.getAccessToken().getJwtToken());
      localStorage.setItem("refresh_token", data.getRefreshToken().getToken());
    });
    const response = await ConsoleAPIClient.postWithAuth(
      "/api/jp/v1/tokens/create",
      {
        token: {
          email,
          account_id,
        },
      }
    );
    return response;
  };

  const getUser = async () => {
    try {
      // 検証用
      Auth.currentSession().then(async (data) => {
        localStorage.setItem("id_token", data.getIdToken().getJwtToken());
        localStorage.setItem(
          "access_token",
          data.getAccessToken().getJwtToken()
        );
        localStorage.setItem(
          "refresh_token",
          data.getRefreshToken().getToken()
        );
      });
      const userData = await Auth.currentAuthenticatedUser();
      return userData;
    } catch (e) {
      return console.log("Not signed in");
    }
  };

  const handleValueInputChange = (
    value: string | number | readonly string[],
    event: React.SyntheticEvent<Element, Event>
  ) => {
    const name: string | null = event.currentTarget.getAttribute("name");
    if (name == null) {
      return;
    }
    const str_value = value.toString();
    setValues({ ...values, [name]: str_value });
  };

  const handleSubmit = useCallback(async () => {
    setIsSubmitting(true);
    setError("");
    setAccountName("");
    setAccountCompany("");
    setAccountEmail("");
    setToken("");
    const response = await CreateToken(values.email, values.accountId);
    setToken(response.data.token.token);
    setAccountName(response.data.token.name);
    setAccountCompany(response.data.token.company);
    setAccountEmail(response.data.token.email);
    if (
      response.data.token.email === "info@revcomm.co.jp" &&
      response.data.token.name === ""
    ) {
      setError("指定されたAccount IDは存在しません。");
    }
    setIsSubmitting(false);
  }, [values.accountId, values.email]);

  return user ? (
    <div>
      <p>サインイン済み</p>
      <p>ユーザー名: {user.attributes.email}</p>
      <button onClick={() => Auth.signOut()}>Sign Out</button>
      <FlexboxGrid justify="center">
        <FlexboxGrid.Item
          style={{
            paddingTop: "25px",
            display: "flex",
            justifyContent: "center",
          }}
          colspan={24}
        >
          <Panel
            shaded
            header={
              i18n.t("console.token_page.create_token_modal.title") as string
            }
            style={{
              width: "500px",
            }}
          >
            <Form>
              {error !== "" ? (
                <Form.Group controlId="error">
                  <Message
                    className={classes["message-alert"]}
                    showIcon
                    type="error"
                    header="Error"
                  >
                    <p>{error}</p>
                  </Message>
                </Form.Group>
              ) : null}
              <Form.Group controlId="email">
                <FormLabel
                  text={
                    i18n.t(
                      "console.token_page.create_token_modal.form.email"
                    ) as string
                  }
                />
                <Input
                  name="email"
                  value={values.email}
                  onChange={handleValueInputChange}
                  type="text"
                  disabled={false}
                  step=""
                  placeHolder={i18n.t("register_form.samples.email") as string}
                />
              </Form.Group>
              <Form.Group controlId="accountId">
                <FormLabel
                  text={
                    i18n.t(
                      "console.token_page.create_token_modal.form.account_id"
                    ) as string
                  }
                />
                <Input
                  name="accountId"
                  value={values.accountId}
                  onChange={handleValueInputChange}
                  type="text"
                  disabled={false}
                  step=""
                  placeHolder={
                    i18n.t("register_form.samples.accountId") as string
                  }
                />
              </Form.Group>

              <Form.Group
                style={{
                  alignItems: "center",
                  justifyContent: "flex-end",
                  display: "flex",
                }}
              >
                <Button
                  text={
                    i18n.t(
                      "console.token_page.create_token_modal.form.submit"
                    ) as string
                  }
                  onClick={handleSubmit}
                  isLoading={isSubmitting}
                />
              </Form.Group>
            </Form>
          </Panel>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <p></p>
      {accountName && accountCompany && accountEmail ? (
        <FlexboxGrid justify="center">
          <FlexboxGrid.Item
            style={{
              paddingTop: "25px",
              display: "flex",
              justifyContent: "center",
            }}
            colspan={24}
          >
            <Panel
              shaded
              header={
                i18n.t(
                  "console.token_page.check_and_send_modal.title"
                ) as string
              }
              style={{
                width: "500px",
              }}
            >
              <Form>
                <p>氏名： {accountName}</p>
                <p>会社名： {accountCompany}</p>
                <p>登録メールアドレス： {accountEmail}</p>
                <p>トークン：</p>
                <p style={{ wordBreak: "break-all" }}>{token}</p>
                <p>オンライン申し込みURL：</p>
                <p style={{ wordBreak: "break-all" }}>
                  {createURL()}
                  <IconButton
                    onClick={() => copyToClipboard(createURL())}
                    icon={<CopyIcon />}
                  >
                    Copy
                  </IconButton>
                </p>
              </Form>
            </Panel>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      ) : null}
    </div>
  ) : (
    <div>
      <p>サインインする</p>
      <button onClick={() => Auth.federatedSignIn()}>Sign In</button>
    </div>
  );
}
