import { FormValueType } from "pages/jp/MiitelMeetingsRegister/type";

export const initialFormValues: FormValueType = {
  company_name: "",
  first_name: "",
  last_name: "",
  phone_number: "",
  email: "",
  number_of_employees: "",
  meeting_tools: [],
  ceo_name: "",
  headquarter: "",
  accepted: "0",
  is_miitel_user: "0",
};

export const HOW_MANY_USERS = [
  { label: "1名", value: 1 },
  { label: "2名", value: 2 },
  { label: "3名", value: 3 },
  { label: "4名", value: 4 },
  { label: "5名", value: 5 },
  { label: "6名", value: 6 },
  { label: "7名", value: 7 },
  { label: "8名", value: 8 },
  { label: "9名", value: 9 },
  { label: "10名", value: 10 },
];

export const MEETING_TOOLS = [
  "Zoom",
  "Microsoft Teams",
  "Google Meet",
  "その他",
];

export const NUM_OF_EMPLOYEES_SELECTIONS = [
  { label: "1〜99名", value: "1〜99名" },
  { label: "100〜499名", value: "100〜499名" },
  { label: "500〜1,999名", value: "500〜1,999名" },
  { label: "2,000名以上", value: "2,000名以上" },
];
