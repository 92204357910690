import React from "react";
import classes from "./SecondaryFooter.module.scss";
import { FlexboxGrid } from "rsuite";

import { CountryCode } from "config/BaseConfig";
import MiiTelMeetingsLogo from "../../../assets/img/MiiTel_Meetings_logo_white.svg";
import PrivacyMarkLogo from "../../../assets/img/privacy_mark.svg";
import SecurityISOLogo from "../../../assets/img/security_ISO.svg";

interface FooterProps {}

const Footer: React.FC<FooterProps> = () => {
  return (
    <FlexboxGrid
      justify="center"
      align="top"
      className={classes["main_css_" + CountryCode]}
    >
      <FlexboxGrid.Item className={classes["top"]}>
        <img
          src={MiiTelMeetingsLogo}
          alt="Logo"
          className={classes["mm-logo-img"]}
        />
      </FlexboxGrid.Item>
      <FlexboxGrid.Item className={classes["bottom"]}>
        <FlexboxGrid.Item className={classes["bottom-item-group"]}>
          <FlexboxGrid.Item className={classes["bottom-item-group-logo"]}>
            <FlexboxGrid.Item
              className={classes["bottom-item-group-logo-section1"]}
            >
              <img src={PrivacyMarkLogo} alt="PrivacyMark" />
              <img src={SecurityISOLogo} alt="PrivacyMark" />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item className={classes["bottom-item-group-text"]}>
              株式会社RevCommは
              <br className={classes["hidden-md"]} />
              情報セキュリティマネジメントシステムの国際規格であるISO27001/ISMSの認証を取得しています。
            </FlexboxGrid.Item>
          </FlexboxGrid.Item>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item className={classes["bottom-copy-link"]}>
          © RevComm Inc.
        </FlexboxGrid.Item>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
};

export default Footer;
