import axios from "axios";
import { ConsoleAPIBaseURL, ConsoleAPIKey } from "../../config/BaseConfig";
import URLService from "../../service/utils/URLService";

export default class ConsoleAPIClient {
  static baseURL = ConsoleAPIBaseURL;
  static apiKey = ConsoleAPIKey;

  static async getWithAuth(path) {
    try {
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("access_token"),
        "Content-Type": "application/json",
        accept: "application/json",
        "x-api-key": this.apiKey,
      };
      return await axios.get(URLService.joinURL(this.baseURL, path), {
        headers,
      });
    } catch (e) {
      localStorage.removeItem("id_token");
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      document.location.href = "/login";
      throw e;
    }
  }

  static async postWithAuth(path, data) {
    try {
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("access_token"),
        "Content-Type": "application/json",
        accept: "application/json",
        "x-api-key": this.apiKey,
      };
      return await axios.post(URLService.joinURL(this.baseURL, path), data, {
        headers,
      });
    } catch (e) {
      localStorage.removeItem("id_token");
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      document.location.href = "/login";
      throw e;
    }
  }

  static async getWithoutAuth(path) {
    try {
      const headers = {
        "Content-Type": "application/json",
        accept: "application/json",
        "x-api-key": this.apiKey,
      };
      return await axios.get(URLService.joinURL(this.baseURL, path), {
        headers,
      });
    } catch (e) {
      throw e;
    }
  }

  static async postWithoutAuth(path, data) {
    try {
      const headers = {
        "Content-Type": "application/json",
        accept: "application/json",
        "x-api-key": this.apiKey,
      };
      return await axios.post(URLService.joinURL(this.baseURL, path), data, {
        headers,
      });
    } catch (e) {
      throw e;
    }
  }
}
