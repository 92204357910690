import { CountryCode } from "config/BaseConfig";
import { Button as RSuiteButton } from "rsuite";
import classes from "./Button.module.scss";

interface ButtonProps {
  text: string;
  onClick: () => Promise<void>;
  isLoading: boolean;
}

const Button = ({ text, onClick, isLoading = false }: ButtonProps) => {
  return (
    <RSuiteButton
      loading={isLoading}
      className={
        CountryCode === "US" ? classes.main_css_us : classes.main_css_jp
      }
      onClick={onClick}
    >
      {text}
    </RSuiteButton>
  );
};
export default Button;
