import { CountryCode } from "config/BaseConfig";
import { Form } from "rsuite";
import SecondaryRequiredLabel from "../SecondaryRequiredLabel";
import classes from "./SecondaryFormLabel.module.scss";

interface Props {
  text: string;
  required?: boolean;
}

const SecondaryFormLabel = ({ text, required }: Props) => {
  return (
    <Form.ControlLabel
      className={
        CountryCode === "US" ? classes["main_css_jp"] : classes["main_css_jp"]
      }
    >
      <span className={classes["text"]}>{text}</span>
      {required && <SecondaryRequiredLabel />}
    </Form.ControlLabel>
  );
};

export default SecondaryFormLabel;
