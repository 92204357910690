import { CountryCode } from "config/BaseConfig";
import { Button as RSuiteButton } from "rsuite";
import classes from "./SecondaryButton.module.scss";

interface ButtonProps {
  text: string;
  onClick: () => Promise<void>;
  isLoading: boolean;
}

const SecondaryButton = ({ text, onClick, isLoading = false }: ButtonProps) => {
  return (
    <RSuiteButton
      loading={isLoading}
      className={
        CountryCode === "US" ? classes.main_css_us : classes.main_css_jp
      }
      onClick={onClick}
    >
      {text}
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='11'
        height='17'
        viewBox='0 0 11 17'
        fill='none'
      >
        <path
          d='M9.91211 9.08008L3.0918 15.9355C2.74023 16.252 2.21289 16.252 1.89648 15.9355L1.08789 15.127C0.771484 14.8105 0.771484 14.2832 1.08789 13.9316L6.50195 8.48242L1.08789 3.06836C0.771484 2.7168 0.771484 2.18945 1.08789 1.87305L1.89648 1.06445C2.21289 0.748047 2.74023 0.748047 3.0918 1.06445L9.91211 7.91992C10.2285 8.23633 10.2285 8.76367 9.91211 9.08008Z'
          fill='white'
        />
      </svg>
    </RSuiteButton>
  );
};
export default SecondaryButton;
