import Button from "components/atoms/Button";
import FormLabel from "components/atoms/FormLabel";
import Input from "components/atoms/Input";
import InputPicker from "components/atoms/InputPicker";
import FormHeaderGroup from "components/molecules/FormHeaderGroup";
import { DeployPage } from "config/BaseConfig";
import usePageTracking from "hooks/useTracking";
import i18n from "i18next";
import React, { useCallback, useEffect, useState } from "react";
import ReactGA from "react-ga4";
import ReCAPTCHA from "react-google-recaptcha";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CheckboxGroup,
  FlexboxGrid,
  Form,
  Message,
  Radio,
  RadioGroup,
} from "rsuite";
import { ValueType } from "rsuite/esm/Checkbox";
import ConsoleAPIClient from "service/utils/ConsoleAPIClient";
import InputCheckbox from "../../../components/atoms/InputCheckbox";
import InputNumberGroup from "../../../components/molecules/InputNumberGroup";
import classes from "./ContinueOrCancelPage.module.scss";

const validateEmail = (email: string) => {
  // メールの形式が正しいかをチェックする正規表現
  const ok_pattern = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  return ok_pattern.test(email);
};

type ContinueOrCancelPageProps = {};
type valueType = {
  email: string;
  company_name: string;
  customer_name: string;
  tenant_code: string;
  phone_number: string;
  request_type: string;
  how_many_users: number;
  cancel_reasons: string[];
  satisfaction_level: number;
  additional_message: string;
};

const ContinueOrCancelPage: React.FC<ContinueOrCancelPageProps> = () => {
  useEffect(() => {
    document.title =
      "トライアルプラン解約・継続申し込み | MiiTel Meetings（ミーテルミーティング）";
  }, []);

  const RequestTypes = [
    { label: "有償契約で利用を継続する", value: "continue" },
    { label: "無償トライアル後解約する", value: "cancel" },
  ];
  usePageTracking();
  const search = useLocation().search;
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [values, setValues] = useState<valueType>({
    email: "",
    company_name: "",
    customer_name: "",
    tenant_code: "",
    phone_number: "",
    request_type: "continue",
    how_many_users: 1,
    cancel_reasons: [],
    satisfaction_level: 0,
    additional_message: "",
  });
  const [progressMessage, setProgressMessage] = useState<string>("");
  const [errors, setErrors] = useState<string[]>([]);

  const isValidInput = (input: string): boolean => {
    const pattern = /^[a-z0-9-]+$/;
    return pattern.test(input);
  };

  useEffect(() => {
    (async () => {
      await ConsoleAPIClient.getWithoutAuth("/api/health");
    })();
  }, [navigate, search]);

  useEffect(() => {
    if (values.request_type === "continue") {
      setValuesEvent(1, "how_many_users");
    } else if (values.request_type === "cancel") {
      setValuesEvent(0, "how_many_users");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.request_type]);

  const handleSubmit = useCallback(async () => {
    ReactGA.initialize("G-DSDMM4BM02");
    ReactGA.event({
      category: "Form",
      action: "Submit",
      label: "Application",
    });
    setIsSubmitting(true);
    setErrors([]);
    if (
      !values.company_name ||
      !values.customer_name ||
      !values.tenant_code ||
      !values.phone_number ||
      !values.email ||
      !values.request_type ||
      (values.request_type === "cancel" && values.satisfaction_level === 0) ||
      (values.request_type === "cancel" &&
        values.cancel_reasons.length === 0) ||
      (values.cancel_reasons.includes("その他") &&
        values.additional_message === "")
    ) {
      setErrors((errors) => [...errors, "全ての項目を入力してください。"]);
      window.scrollTo({ top: 0, behavior: "smooth" });
      setIsSubmitting(false);
      return;
    }
    if (!validateEmail(values.email)) {
      setErrors((errors) => [...errors, "メールアドレスが不正です。"]);
      window.scrollTo({ top: 0, behavior: "smooth" });
      setIsSubmitting(false);
      return;
    }
    if (!isValidInput(values.tenant_code)) {
      setErrors((errors) => [...errors, "テナントコードが不正です。"]);
      window.scrollTo({ top: 0, behavior: "smooth" });
      setIsSubmitting(false);
      return;
    }

    try {
      setProgressMessage("お客様情報・申請内容を登録をしています。");
      await ConsoleAPIClient.postWithoutAuth(
        "/api/jp/v1/continue-or-cancel-request",
        {
          continue_or_cancel_request: {
            email: values.email,
            company_name: values.company_name,
            customer_name: values.customer_name,
            tenant_code: values.tenant_code,
            phone_number: values.phone_number,
            request_source: "mm-free-trial-202305",
            request_type: values.request_type,
            request_data: {
              how_many_users: values.how_many_users,
              cancel_reasons: values.cancel_reasons,
              satisfaction_level: values.satisfaction_level,
              additional_message: values.additional_message,
            },
          },
        }
      );
      navigate("/finished");
      setIsSubmitting(false);
    } catch {
      window.scrollTo({ top: 0, behavior: "smooth" });
      setProgressMessage("");
      setIsSubmitting(false);
    }
  }, [navigate, values]);

  const handleValueInputChange = (
    value: string | number | readonly string[],
    event: React.SyntheticEvent<Element, Event>
  ) => {
    const name: string | null = event.currentTarget.getAttribute("name");
    if (name == null) {
      return;
    }
    const str_value = value.toString();
    setValues({ ...values, [name]: str_value });
  };
  const setValuesEvent = (
    value: string | number | readonly string[] | ValueType[],
    name: string
  ) => {
    const obj = {
      ...values,
      [name]: value,
    };
    setValues(obj);
  };
  const handleMinus = (target: string) => {
    if (target === "how_many_users") {
      const nextValue = Number(values[target]) - 1;
      if (nextValue < 1) {
        return;
      }
      setValuesEvent(nextValue, target);
    }
  };
  const handlePlus = (target: string) => {
    if (target === "how_many_users") {
      const nextValue = Number(values[target]) + 1;
      setValuesEvent(nextValue, target);
    }
  };

  const handleRequestTypesSelectInputChange = (
    value: string | number | readonly string[],
    _: React.SyntheticEvent<Element, Event>
  ) => {
    setValuesEvent(value, "request_type");
  };

  // Checkbox
  const data = [
    "①文字起こし、音声認識の精度が期待に沿わなかった",
    "②分析機能への不満（行いたい分析ができない等）",
    "③外部連携ができない（希望のツールと連携できない）",
    "④サービスの操作感に不満",
    "⑤費用対効果（利用料金が高い等）",
    "⑥運用が定着せず（組織文化が醸成できず等）",
    "その他",
  ];
  const handleCheckBoxChange = (value: ValueType[]) => {
    setValuesEvent(value, "cancel_reasons");
  };

  // Radio
  const satisfaction_levels = [1, 2, 3, 4, 5];
  const handleRadioChange = (value: string | number | readonly string[]) => {
    setValuesEvent(value, "satisfaction_level");
  };

  return (
    <div>
      <FlexboxGrid justify="center" style={{ paddingBlock: "60px" }}>
        <FlexboxGrid
          justify="center"
          style={{
            width: "100%",
          }}
        >
          <FormHeaderGroup
            sub_header={"TENANT FORM"}
            header={"ご利用テナント申請"}
          />
          <FlexboxGrid.Item
            style={{
              paddingTop: "25px",
              display: "flex",
              justifyContent: "center",
            }}
            colspan={24}
          >
            <Form className={classes["form-content-flexgrid"]}>
              {errors.length > 0 ? (
                <Message
                  className={classes["message-alert"]}
                  showIcon
                  type="error"
                  header="Error"
                >
                  {errors.map((error, idx) => {
                    return <p key={idx}>{error}</p>;
                  })}
                </Message>
              ) : null}
              <Form.Group
                controlId="email"
                style={{ paddingBlock: "20px", marginBottom: "0px" }}
              >
                <FormLabel text={i18n.t("register_form.email") as string} />
                <Input
                  name="email"
                  value={values.email}
                  onChange={handleValueInputChange}
                  type="email"
                  disabled={false}
                  step=""
                  placeHolder={i18n.t("register_form.samples.email") as string}
                />
              </Form.Group>
              <Form.Group
                controlId="phone_number"
                style={{ paddingBlock: "20px", marginBottom: "0px" }}
              >
                <FormLabel
                  text={i18n.t("register_form.phone_number") as string}
                />
                <Input
                  name="phone_number"
                  value={values.phone_number}
                  onChange={handleValueInputChange}
                  type="phone_number"
                  disabled={false}
                  step=""
                  placeHolder={
                    i18n.t("register_form.samples.phone_number") as string
                  }
                />
              </Form.Group>
              <Form.Group
                controlId="company_name"
                style={{ paddingBlock: "20px", marginBottom: "0px" }}
              >
                <FormLabel
                  text={i18n.t("register_form.company_name") as string}
                />
                <Input
                  name="company_name"
                  value={values.company_name}
                  onChange={handleValueInputChange}
                  type="text"
                  disabled={false}
                  step=""
                  placeHolder={
                    i18n.t("register_form.samples.company_name") as string
                  }
                />
              </Form.Group>
              <Form.Group controlId="name">
                <FormLabel
                  text={i18n.t("register_form.first_name") as string}
                />
                <Input
                  name="customer_name"
                  value={values.customer_name}
                  onChange={handleValueInputChange}
                  type="text"
                  disabled={false}
                  step=""
                  placeHolder={i18n.t("card_setup.samples.ceo_name") as string}
                />
              </Form.Group>
              <Form.Group controlId="tenant_code">
                <FormLabel text={i18n.t("functions.tenant_code") as string} />
                <Input
                  name="tenant_code"
                  value={values.tenant_code}
                  onChange={handleValueInputChange}
                  type="tenant_code"
                  disabled={false}
                  step=""
                  placeHolder={
                    i18n.t("functions.samples.tenant_code") as string
                  }
                />
                <Form.HelpText>
                  *テナント名がわからない場合は、MiiTel Analytics
                  の右上に表示されるアカウントアイコンからご確認いただけます。
                </Form.HelpText>
              </Form.Group>
              <Form.Group
                controlId="request_type"
                style={{ paddingBlock: "20px", marginBottom: "0px" }}
              >
                <FormLabel text="トライアル期間終了後のご契約に関して" />
                <InputPicker
                  name="request_type"
                  data={RequestTypes}
                  value={values.request_type}
                  onChange={handleRequestTypesSelectInputChange}
                  disabled={false}
                />
              </Form.Group>
              <Form.Group
                controlId="how_many_users"
                style={{ paddingBlock: "20px", marginBottom: "0px" }}
              >
                <FormLabel
                  text={i18n.t("functions.zoom_user_amount") as string}
                />
                <InputNumberGroup
                  name="how_many_users"
                  value={values.how_many_users}
                  onChange={handleValueInputChange}
                  handleMinus={handleMinus}
                  handlePlus={handlePlus}
                  disabled={values.request_type === "cancel"}
                />
              </Form.Group>

              <Form.Group
                controlId="cancel_reasons"
                style={{ paddingBlock: "20px", marginBottom: "0px" }}
              >
                <FormLabel text="ご解約理由（ご解約をされる場合）" />
                <Form.HelpText>
                  ご解約理由に当てはまるものをご選択ください（複数選択可）
                  <br />
                  ※該当する理由がない方は「その他」を選択の上、その他ご意見に内容をご記載ください
                </Form.HelpText>
                {/* TODO: Move to atom component */}
                <CheckboxGroup
                  inline
                  name="checkboxList"
                  value={values.cancel_reasons}
                  onChange={handleCheckBoxChange}
                  style={{ backgroundColor: "#f7f7fa", marginLeft: "0px" }}
                  className="rs-flex-box-grid rs-flex-box-grid-top rs-flex-box-grid-start"
                >
                  {data.map((item) => (
                    <FlexboxGrid.Item key={item} colspan={24}>
                      <InputCheckbox value={item} />
                    </FlexboxGrid.Item>
                  ))}
                </CheckboxGroup>
              </Form.Group>

              <Form.Group
                controlId="satisfaction_level"
                style={{ paddingBlock: "20px", marginBottom: "0px" }}
              >
                <FormLabel text="「MiiTel Meetings」というサービスへの評価" />
                {/* TODO: Move to atom component */}
                <RadioGroup
                  inline
                  name="satisfaction_level"
                  value={values.satisfaction_level}
                  onChange={handleRadioChange}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingInline: "20px",
                    paddingBlock: "10px",
                    backgroundColor: "#f7f7fa",
                    marginLeft: "0px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "#00548E",
                    }}
                  >
                    不満
                  </div>
                  {satisfaction_levels.map((item) => (
                    <Radio key={item} value={item}></Radio>
                  ))}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "#00548E",
                    }}
                  >
                    満足
                  </div>
                </RadioGroup>
              </Form.Group>

              <Form.Group
                controlId="additional_message"
                style={{ paddingBlock: "20px", marginBottom: "0px" }}
              >
                <FormLabel text="その他ご意見" />
                <Input
                  name="additional_message"
                  value={values.additional_message}
                  onChange={handleValueInputChange}
                  type="text"
                  disabled={false}
                  step=""
                  placeHolder={
                    "MiiTel Meetingsにどういった機能やサービス、サポートがあるとよかったか等"
                  }
                  rows={5}
                />
              </Form.Group>

              <Form.Group
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <ReCAPTCHA
                  sitekey="6LdfqOQZAAAAAFe8i_GhcQWu39jG2fcp85xOcKUu"
                  onChange={(e: any) => console.log(e)}
                />
              </Form.Group>

              <Form.Group
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  display: DeployPage === "APPLICATION_FORM" ? "flex" : "none",
                }}
              >
                <p>
                  ご登録から1ケ月間無償でご利用いただけます。 <br />
                  無償期間終了の2週間前にご案内を差し上げます。
                </p>
              </Form.Group>

              {isSubmitting && progressMessage !== "" ? (
                <Form.Group>
                  <Message
                    className={classes["message-alert"]}
                    showIcon
                    type="info"
                    header="しばらくお待ち下さい。"
                  >
                    <p>{progressMessage}</p>
                  </Message>
                </Form.Group>
              ) : null}

              <Form.Group
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <Button
                  text={
                    DeployPage === "APPLICATION_FORM"
                      ? "下記に同意し支払い情報入力へ"
                      : "下記に同意し入力内容を送信"
                  }
                  onClick={handleSubmit}
                  isLoading={isSubmitting}
                />
              </Form.Group>

              <Form.Group
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <a
                  href="https://www.revcomm.co.jp/terms/"
                  target="_blank"
                  rel="noreferrer noopener"
                  style={{ color: "#209CFF" }}
                >
                  個人情報の取り扱い
                </a>
                <p style={{ paddingInline: "5px" }}>/</p>
                <a
                  href="https://www.revcomm.co.jp/privacypolicy/"
                  target="_blank"
                  rel="noreferrer noopener"
                  style={{ color: "#209CFF" }}
                >
                  個人情報保護方針
                </a>
              </Form.Group>
            </Form>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </FlexboxGrid>
    </div>
  );
};

export default ContinueOrCancelPage;
